import { useQuery } from "react-query";
import { CollectionEmbeddingMappingFetchOptions } from "../models/types";
import { getCollectionEmbeddingMappings } from "../services/CollectionEmbeddingMappingService";
import { QueryKey } from "../state/QueryStore";

export const useCollectionEmbeddingMapping = (params: CollectionEmbeddingMappingFetchOptions) => {
  return useQuery([QueryKey.CollectionStore, params], async () => {
    const { data } = await getCollectionEmbeddingMappings(params);
    return data;
  });
};
