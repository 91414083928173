import { useQuery } from "react-query";
import { CollectionStoreFetchOptions } from "../models/types";
import { getCollectionStore, getCollectionStores } from "../services/CollectionStoreService";
import queryClient, { QueryKey } from "../state/QueryStore";

export const useCollectionStores = (params: CollectionStoreFetchOptions) => {
  return useQuery([QueryKey.CollectionStore, params], async () => {
    const { data } = await getCollectionStores(params);
    return data;
  });
};

export const useCollectionStore = (collectionId: string) => {
  return useQuery([QueryKey.CollectionStore, collectionId], async () => {
    if (!collectionId || collectionId === "") {
      return undefined;
    }
    const { data } = await getCollectionStore(collectionId);
    return data;
  });
};

export const fetchCollectionStores = (params: CollectionStoreFetchOptions) => {
  return queryClient.fetchQuery([QueryKey.CollectionStore, params], async () => {
    const { data } = await getCollectionStores(params);
    return data;
  });
};

export const searchCollectionStores = async (searchText: string) => {
  const collectionStores = await fetchCollectionStores({ search: searchText });
  return collectionStores.results;
};
