import { Box, Typography, useTheme } from "@mui/material";
import { KPIBadge } from "../TaskSuggestionDisplay";
import { ScreenSmallerThen } from "../../utilities/UIHelper";
import { MetricKPIs } from "../MetricKPIs";
import { AllKPIs } from "../modals/custom-fields/KPIsModal";
import { useState } from "react";

type KPIsProps = {
  value: string[];
};

export const KPIs = ({ value }: KPIsProps) => {
  const theme = useTheme();

  const [openAllKPIs, setOpenAllKPIs] = useState(false);

  const isSmallScreen1 = ScreenSmallerThen("1420px");
  const isSmallScreen2 = ScreenSmallerThen("1160px");
  const maxIndex = isSmallScreen2 ? 2 : isSmallScreen1 ? 3 : 4;

  return (
    <>
      <AllKPIs open={openAllKPIs} onClose={() => setOpenAllKPIs(false)} value={value} />
      <Box display="flex" flexDirection="column" gap="6px" width="100%">
        <Box display="flex" gap="10px" alignItems="center" width="100%">
          {value.length !== 0 ? (
            <>
              {value?.map((metric: any, index: number) => {
                return index < maxIndex ? <MetricKPIs key={index} metricId={metric} /> : null;
              })}
            </>
          ) : (
            <KPIBadge color={theme.palette.custom.secondaryStatusColor} label="No KPIs" value="-" />
          )}
        </Box>
        {value.length > maxIndex && (
          <Box display="flex" justifyContent="flex-end">
            <Box
              onClick={() => setOpenAllKPIs(true)}
              sx={{
                ":hover": {
                  cursor: "pointer",
                },
              }}
            >
              <Typography
                variant="button"
                color={theme.palette.custom.hyperlink}
                sx={{
                  ":hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                Show More
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};
