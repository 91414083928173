const config = {
  appBaseUrl: process.env.REACT_APP_BASE_URL,
  stytch: {
    signupRedirect: process.env.REACT_APP_STYTCH_SIGNUP_REDIRECT_URL,
    loginRedirect: process.env.REACT_APP_STYTCH_LOGIN_REDIRECT_URL,
    addOAuthRedirect: process.env.REACT_APP_STYTCH_ADD_OAUTH_REDIRECT_URL,
    publicToken: process.env.REACT_APP_STYTCH_PUBLIC_TOKEN,
    stytchBaseLink: process.env.REACT_APP_STYTCH_BASE_LINK,
  },
  intercom: {
    baseUrl: process.env.REACT_APP_INTERCOM_BASE_URL,
    appId: process.env.REACT_APP_INTERCOM_APP_ID,
  },
  github: {
    appInstallationLink: process.env.REACT_APP_FAIRO_GITHUB_APP_INSTALLATION_LINK,
  },
  links: {
    privacyPolicy: "https://www.fairo.ai/privacy-policy",
    termsAndConditions: "https://www.fairo.ai/terms",
  },
  featureToggle: {
    enableTestRun: process.env.REACT_APP_ENABLE_TEST_RUN_SCREEN === "true",
  },
  ids: {
    fairoUserUUID: process.env.REACT_APP_FAIRO_USER_UUID,
    fairoOrgUUID: process.env.REACT_APP_FAIRO_ORG_UUID,
  },
  emails: {
    requestNewMetric: process.env.REACT_APP_REQUEST_NEW_METRIC_EMAIL,
  },
  mlflow: {
    baseUrl: process.env.REACT_APP_MLFLOW_BASE_URL,
  },
  appHostname: process.env.REACT_APP_HOSTNAME,
};

export default config;
