import { Box } from "@mui/material";
import { NavbarIcon } from "../atoms/navbar/Icon";
import { TableTextWithEllipsis } from "./TableTextWithEllipsis";

type FairoUserProps = {
  hideName?: boolean;
};

export const FairoUser = ({ hideName = false }: FairoUserProps) => {
  return (
    <Box display="flex" flexDirection="row" gap="3px">
      <NavbarIcon
        variant="created-by-fairo"
        color="none"
        sx={{ width: "20px", height: "20px", minHeight: "20px", minWidth: "20px" }}
      />
      {!hideName && <TableTextWithEllipsis value="Fairo" />}
    </Box>
  );
};
