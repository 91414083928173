import { Box, Typography, useTheme } from "@mui/material";
import { RiskScoreBadge } from "../atoms/StatusBadge";
import UserTag from "../components/UserTag";
import { Question, RegistryFieldType } from "../models/types";
import { AssetListReadOnlyCustomField } from "../molecules/custom-fields/AssetListCustomField";
import { MetricListReadOnlyCustomField } from "../molecules/custom-fields/MetricCustomField";
import { NumberWithUnitReadOnly } from "../molecules/custom-fields/NumberWithUnitCustomField";
import { ReferenceListReadOnlyCustomField } from "../molecules/custom-fields/ReferenceListCustomField";
import { TagListReadOnlyCustomField } from "../molecules/custom-fields/TagListCustomField";
import { UseCaseListReadOnlyCustomField } from "../molecules/custom-fields/UseCaseCustomField";
import { DefaultBadgeList } from "../molecules/DefaultBadgeList";
import { JSONTableView } from "../molecules/JSONTableView";
import { JSONView } from "../molecules/JSONView";
import { ShowMore } from "../molecules/ShowMore";
import {
  checkAndReturnValidJSON,
  defaultDateDisplay,
  isJSON,
  isValidUUID,
} from "../utilities/UIHelper";
import { AssetBadge } from "./assets/AssetBadge";
import { DisplayElementBadge } from "./compliance/elements/DisplayElementBadge";
import { AssetWithDescriptionListCustomField } from "./custom-fields/AssetWithDescriptionCustomField";
import { DataOriginReadOnly } from "./custom-fields/DataOriginReadOnly";
import { FileOrLinkCustomField } from "./custom-fields/FileOrLinkCustomField";
import { MetricResultTestResultListCustomField } from "./custom-fields/MetricResultTestResultListCustomField";
import { MetricWithDescriptionListCustomField } from "./custom-fields/MetricWithDescriptionCustomField";
import { MultipleSelectionReadOnly } from "./custom-fields/MultipleSelection";
import { PotentialHarmListFieldReadOnly } from "./custom-fields/PotentialHarms";
import { RelationshipComplexityReadOnly } from "./custom-fields/RelationshipComplexityReadOnly";
import { SingleSelectionReadOnly } from "./custom-fields/SingleSelection";
import { DepartmentListReadOnlyCustomField } from "../molecules/custom-fields/DepartmentCustomField";
import ErrorHandling from "../molecules/ErrorHandling";
import { KPIs } from "./custom-fields/KPIs";
import { NavbarIcon } from "../atoms/navbar/Icon";
import { TableTextWithEllipsis } from "../molecules/TableTextWithEllipsis";
import { CollectionStoreReadOnlyCustomField } from "../molecules/custom-fields/CollectionStoreCustomField";

type DisplayReadOnlyCustomFieldProps = {
  field_type: RegistryFieldType;
  question?: Question;
  value: string | string[] | number;
  sx?: any;
  padding?: string;
  disabled: boolean;
  assetType?: string;
  label?: string;
  model?: string;
  maxChar?: number;
  showJSONTableView?: boolean;
};

export const NameAndDescriptionReadOnly = (props: { value: string; disabled: boolean }) => {
  const { value, disabled } = props;
  const parsedValue = checkAndReturnValidJSON(value, []);
  const disabledTypographyEffect = {
    opacity: disabled ? 0.5 : 1,
  };
  return (
    <Box display="flex" flexDirection="column" gap="5px">
      {parsedValue?.length > 0 ? (
        parsedValue.map((item: any, index: number) => {
          const obj = JSON.parse(item);
          return (
            <>
              <Typography
                fontWeight={600}
                sx={{
                  ...(disabled && disabledTypographyEffect),
                }}
                variant="body2"
              >
                {obj.name}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  ...(disabled && disabledTypographyEffect),
                }}
              >
                {obj.description}
              </Typography>
            </>
          );
        })
      ) : (
        <Typography variant="body2">No intended uses</Typography>
      )}
    </Box>
  );
};

export const ExternalLink = (props: { value: string; disabled: boolean }) => {
  const { value, disabled } = props;
  const parsedValue = JSON.parse(value);
  const disabledTypographyEffect = {
    opacity: disabled ? 0.5 : 1,
  };
  const theme = useTheme();
  return (
    <Box display="flex" flexDirection="column" gap="5px">
      {parsedValue.map((item: any, index: number) => {
        const obj = JSON.parse(item);
        return (
          <>
            <Typography
              fontWeight={600}
              sx={{
                ...(disabled && disabledTypographyEffect),
              }}
              variant="body2"
            >
              {obj.description}
            </Typography>
            <Typography
              onClick={() => !disabled && window.open(obj.link, "_blank")}
              variant="body2"
              color={theme.palette.custom.blueTypography}
              sx={{
                cursor: disabled ? "default" : "pointer",
                ...(disabled && disabledTypographyEffect),
              }}
            >
              {obj.link}
            </Typography>
          </>
        );
      })}
    </Box>
  );
};

export const Hyperlink = ({ value }: { value: string }) => {
  const theme = useTheme();
  return value !== "" ? (
    <Box display="flex" maxWidth="90%" flexDirection="row" gap="5px" alignItems="center">
      <NavbarIcon
        variant={"hyperlink"}
        sx={{
          width: "18px",
          minWidth: "18px",
          minHeight: "18px",
          height: "18px",
        }}
        color={theme.palette.custom.hyperlink}
      />
      <TableTextWithEllipsis
        value={value}
        fullWidth={false}
        expanded
        onClick={() => {
          window.open(value, "_blank");
        }}
        color={theme.palette.custom.hyperlink}
        tootipPlacement="bottom-start"
      />
    </Box>
  ) : null;
};

export const DisplayReadOnlyCustomField = (props: DisplayReadOnlyCustomFieldProps) => {
  const {
    field_type,
    value,
    disabled,
    padding = "10px",
    question,
    assetType,
    label,
    model,
    maxChar = 250,
    showJSONTableView = false,
  } = props;
  const disabledTypographyEffect = {
    opacity: disabled ? 0.5 : 1,
  };
  return (
    <Box paddingLeft={padding}>
      {(() => {
        switch (field_type) {
          case "Date":
            return (
              <Typography
                sx={{
                  ...(disabled && disabledTypographyEffect),
                }}
                variant="body2"
              >
                {typeof value === "string" ? defaultDateDisplay(value) : ""}
              </Typography>
            );
          case "Name and Description list":
            return (
              <NameAndDescriptionReadOnly
                value={typeof value === "string" ? value : ""}
                disabled={disabled}
              />
            );
          case "Potential Harm":
            return (
              <PotentialHarmListFieldReadOnly
                value={typeof value === "string" ? JSON.parse(value) : ""}
                disabled={disabled}
              />
            );
          case "External Link":
            return (
              <ExternalLink value={typeof value === "string" ? value : ""} disabled={disabled} />
            );
          case "Element list":
            return (
              <Box display="flex" flexDirection="row" flexWrap="wrap" gap="10px">
                {Array.isArray(value) &&
                  value.map((item, index) => <DisplayElementBadge key={index} elementId={item} />)}
              </Box>
            );
          case "Tag":
            return <TagListReadOnlyCustomField value={typeof value === "string" ? [value] : []} />;
          case "Tag List":
            return <TagListReadOnlyCustomField value={checkAndReturnValidJSON(value, [])} />;
          case "Formatted Text":
            return (
              <ShowMore
                description={typeof value === "string" ? value : ""}
                maxChar={maxChar}
                richText={true}
              />
            );
          case "File or Link":
            return (
              <FileOrLinkCustomField
                value={typeof value === "string" ? value : null}
                relatedObjectId={""}
                onChange={() => {}}
                edit={false}
              />
            );
          case "JSON":
            return typeof value === "string" && isJSON(value) ? (
              showJSONTableView ? (
                <ErrorHandling>
                  <JSONTableView json={value} />
                </ErrorHandling>
              ) : (
                <JSONView value={value} />
              )
            ) : (
              <ShowMore description={value.toString()} />
            );
          case "Risk Level":
            return (
              <Box display="flex" alignItems="center" flexDirection="row" gap="10px">
                <RiskScoreBadge size="small" score={Number(value)} />
                <Typography variant="body2">
                  {question?.choices?.find((opt) => opt.value === value)?.label}
                </Typography>
              </Box>
            );
          case "Single Selection":
            return (
              <SingleSelectionReadOnly
                value={typeof value === "string" ? value : ""}
                choices={question?.choices ?? []}
              />
            );
          case "Data Origin":
            return typeof value === "string" ? (
              <DataOriginReadOnly value={JSON.parse(value)} />
            ) : null;
          case "Relationship Complexity":
            return typeof value === "string" ? (
              <RelationshipComplexityReadOnly value={JSON.parse(value)} />
            ) : null;
          case "Number with Unit":
            return <NumberWithUnitReadOnly value={value} />;
          case "Asset list":
          case "Dataset list":
          case "Model list":
            return value ? (
              <AssetListReadOnlyCustomField value={checkAndReturnValidJSON(value, [])} />
            ) : (
              <></>
            );
          case "User list":
            const users = checkAndReturnValidJSON(value, []);
            return (
              <Box display="flex" flexDirection="row" flexWrap="wrap" gap="5px">
                {users.map((user: any, index: number) => {
                  const validUUID = isValidUUID(user);
                  return (
                    <UserTag
                      key={index}
                      showNameInitials
                      userId={validUUID ? user : undefined}
                      name={user}
                    />
                  );
                })}
              </Box>
            );
          case "String list":
            const strings = checkAndReturnValidJSON(value, []);
            return <DefaultBadgeList expanded={true} badges={strings} />;
          case "Languages":
            const languages = checkAndReturnValidJSON(value, []);
            return <DefaultBadgeList badges={languages} />;
          case "Reference list":
            return <ReferenceListReadOnlyCustomField value={checkAndReturnValidJSON(value, [])} />;
          case "Asset with Description list":
            return typeof value === "string" ? (
              <AssetWithDescriptionListCustomField
                value={value}
                onChange={() => {}}
                assetType={assetType ?? "Dataset"}
                edit={false}
                label={label ?? "Asset"}
              />
            ) : null;
          case "Metric with Description list":
            return typeof value === "string" ? (
              <MetricWithDescriptionListCustomField
                value={value}
                onChange={() => {}}
                edit={false}
                label={label ?? "Metric"}
              />
            ) : null;
          case "Metric Result Test Result list":
            return (
              <MetricResultTestResultListCustomField
                value={typeof value === "string" ? value : ""}
                model={model ?? ""}
              />
            );
          case "Multiple Selection":
            return (
              <MultipleSelectionReadOnly
                value={typeof value === "string" ? value : "[]"}
                choices={question?.choices ?? []}
              />
            );
          case "Asset":
            return typeof value === "string" ? <AssetBadge assetId={value} /> : <></>;
          case "Metric list":
            return (
              <MetricListReadOnlyCustomField
                oldBadge={false}
                value={checkAndReturnValidJSON(value, [])}
              />
            );
          case "Use Case list":
            return <UseCaseListReadOnlyCustomField value={checkAndReturnValidJSON(value, [])} />;
          case "User":
            return <UserTag userId={value.toString()} />;
          case "Department list":
            return <DepartmentListReadOnlyCustomField value={checkAndReturnValidJSON(value, [])} />;
          case "KPIs":
            return <KPIs value={checkAndReturnValidJSON(value, [])} />;
          case "Hyperlink":
            return <Hyperlink value={value.toString()} />;
          case "Collection list":
            return (
              <CollectionStoreReadOnlyCustomField value={checkAndReturnValidJSON(value, [])} />
            );
          default:
            return (
              <Typography
                sx={{
                  ...(disabled && disabledTypographyEffect),
                }}
                variant="body2"
              >
                {value}
              </Typography>
            );
        }
      })()}
    </Box>
  );
};
