import { CollectionStore, CollectionStoreFetchOptions } from "../models/types";
import { ListResponse } from "../utilities/ApiResponseHelper";
import { httpGetAuthenticated } from "./ApiService";

const endpoints = {
  list: () => "/collection_stores",
  retrieve: (id: string) => `/collection_stores/${id}`,
};

export const getCollectionStore = (id: string) => {
  return httpGetAuthenticated<CollectionStore>(endpoints.retrieve(id));
};

export const getCollectionStores = (params: CollectionStoreFetchOptions) => {
  return httpGetAuthenticated<ListResponse<CollectionStore>>(endpoints.list(), {
    params,
  });
};
