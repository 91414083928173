import { Box, Typography, useTheme } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { NavbarIcon } from "../atoms/navbar/Icon";
import { Table } from "../components/Table";
import { APIFetchOptions, APIGetListHook, FilterOption, QueryFilter } from "../models/types";
import { CollapsableComponent } from "../molecules/CollapsableComponent";
import ColumnVisibility, { IColumnVisibility } from "../molecules/ColumnVisibility";
import { DateFilter, IDateFilter } from "../molecules/DateFilter";
import { Filter, IFilterVisibility } from "../molecules/Filter";
import { QueryFilterControl } from "../molecules/QueryFilterControl";
import { TablePagination } from "../molecules/TablePagination";
import { TableSearch } from "../molecules/TableSearch";
import { ListResponse } from "../utilities/ApiResponseHelper";
import { ScreenSmallerThen } from "../utilities/UIHelper";
import { isSameQueryFilter, queryDictFromQueryFilters } from "../utilities/common";
import { APITableLoader } from "./skeleton-loaders/APITableLoader";

type APITableProps<T extends { id: string }, P extends APIFetchOptions, F extends string> = {
  /** Hook to fetch the API data */
  useGetData: APIGetListHook<T, P>;
  /** params dict that is passed to the `useGetData` hook */
  queryParams: P;
  /** Column display information */
  columnsVisibility: IColumnVisibility<T>[];
  /** Table size */
  tableSize?: "small" | "big";
  /** Hide column visibility optional */
  hideColumnVisibility?: boolean;
  /** Filter data */
  filterData?: IFilterVisibility<T>[];
  /** Categories Visibility */
  filtersVisibility?: boolean;
  /** Type of filter */
  filterType?: string;
  /** Name for the filter */
  filterName?: string;
  /** Category params dict that is passed to the 'useGetData' hook */
  filterParams?: P;
  /** Date Filter */
  dateFilters?: IDateFilter[];
  /** Optional filter config that enables search functionality  */
  filterOptions?: FilterOption<F>[];
  /** `onClick` handler for the row*/
  rowOnClick?: (row: T) => void;
  /** Component to show when there's no data */
  emptyTableComponent?: React.ReactNode;
  /** Optional component to render to the right of filter controls */
  secondComponent?: React.ReactElement;
  /** Optional title for the table */
  title?: string;
  /** Optional make table stay always filling the screen */
  bigFixedHeight?: string;
  /** Optional make empty component on small screen smaller */
  smallEmptyHeight?: boolean;
  /** Display Tags */
  displayTags?: boolean;
  /** Optional customization props */
  sx?: {
    borderTopRightRadius?: string;
    borderTopLeftRadius?: string;
    borderBottomLeftRadius?: string;
    borderBottomRightRadius?: string;
  };
  shareTotalRowsCallback?: (totalRows: number | undefined) => void;
  refetchCondition?: (data: ListResponse<T>) => boolean | undefined;
  refetchInterval?: number;
  showNoData?: boolean;
  showPagination?: boolean;
  collapsableTableComponent?: React.ReactElement;
  collapsableComponenetLabel?: string;
  collapsableComponentLabelColor?: string;
  showBottomBorderOnly?: boolean;
  borderRight?: boolean;
  borderTop?: boolean;
  titleInline?: boolean;
  /** Optional collapsable table */
  collapsable?: boolean;
  titleRightComponent?: React.ReactNode;
  id?: string;
  hideOnEmpty?: boolean;
  collapsedInitialState?: boolean;
  expandable?: boolean;
  expandOnResults?: boolean;
  tableExpandableComponent?: (row: T) => React.ReactNode;
  showFirstExpanded?: boolean;
  minEmptyHeight?: boolean;
  searchBarMaxWidth?: string;
};

export const APITable = <T extends { id: string }, P extends APIFetchOptions, F extends string>(
  props: APITableProps<T, P, F>
) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";
  const {
    id,
    collapsable = false,
    useGetData,
    columnsVisibility: _columnsVisibility,
    rowOnClick,
    emptyTableComponent,
    secondComponent,
    filterOptions,
    title,
    sx,
    bigFixedHeight,
    hideColumnVisibility,
    queryParams: _queryParams,
    filterData: _filterData,
    filtersVisibility,
    filterType,
    filterName,
    dateFilters: _dateFilters,
    filterParams: _filterParams,
    shareTotalRowsCallback,
    refetchCondition,
    refetchInterval,
    smallEmptyHeight,
    tableSize,
    showNoData = false,
    displayTags = false,
    showPagination = true,
    collapsableTableComponent,
    showBottomBorderOnly = false,
    collapsableComponenetLabel = "items",
    collapsableComponentLabelColor = theme.palette.custom.primaryBackground,
    borderRight = true,
    borderTop = true,
    titleInline = false,
    titleRightComponent,
    hideOnEmpty = false,
    collapsedInitialState = false,
    expandable = false,
    tableExpandableComponent,
    showFirstExpanded = false,
    expandOnResults,
    minEmptyHeight = false,
    searchBarMaxWidth,
  } = props;
  const [page, setPage] = useState<number>(1);
  const [collapsed, setCollapsed] = useState<boolean>(collapsedInitialState);
  const [rowsPerPage, setRowsPerPage] = useState<number>(tableSize === "big" ? 10 : 5);
  const [collapsedTable, setCollapsedTable] = useState<boolean>(collapsedInitialState);
  const [filterData, setFilterData] = useState<IFilterVisibility<T>[] | undefined>(_filterData);
  const [dateFiltersData, setDateFiltersData] = useState<IDateFilter[] | undefined>(_dateFilters);
  const [visibilityMap, setVisibilityMap] = useState(
    _columnsVisibility.reduce<{ [field: string]: boolean }>((visibilityMap, columnVisibility) => {
      visibilityMap[columnVisibility.field] = columnVisibility.visible;
      return visibilityMap;
    }, {})
  );
  const showAllColumns = () => {
    // reset to default visibility
    setVisibilityMap(
      _columnsVisibility.reduce<{ [field: string]: boolean }>((visibilityMap, columnVisibility) => {
        visibilityMap[columnVisibility.field] = columnVisibility.visible;
        return visibilityMap;
      }, {})
    );
  };
  const [queryFilters, setQueryFilters] = useState<QueryFilter<F>[]>([]);
  const queryParams = {
    page,
    page_size: rowsPerPage,
    ...queryDictFromQueryFilters(queryFilters),
    ..._queryParams,
  };

  const [categoryFilters, setCategoryFilters] = useState<QueryFilter<F>[]>([]);
  const [dateFilters, setDateFilters] = useState<QueryFilter<F>[]>([]);

  const [filters, setFilters] = useState<QueryFilter<F>[]>([]);
  const filterParams = {
    page,
    page_size: rowsPerPage,
    ...queryDictFromQueryFilters(filters),
    ..._queryParams,
  };
  const [deselectAll, setDeselectAll] = useState<boolean>(false);

  const { data, isLoading, refetch } = useGetData(filterParams);

  const showTable = collapsableTableComponent ? !collapsedTable : true;

  // Always reconstruct columnVisibility from props instead of storing in state
  // Some of the column data like click handlers and render functions may change on the fly
  // Only the `visible` property should be stored in local state
  const columnsVisibility = useMemo(
    () =>
      _columnsVisibility.map((colVis) => ({
        ...colVis,
        visible: visibilityMap[colVis.field] ?? colVis.visible,
      })),
    [visibilityMap, _columnsVisibility]
  );
  const changeVisibility = (newColumnsVisibility: IColumnVisibility<T>[]) => {
    const newVisibiltyMap = newColumnsVisibility.reduce<{ [field: string]: boolean }>(
      (visibilityMap, columnVisibility) => {
        visibilityMap[columnVisibility.field] = columnVisibility.visible;
        return visibilityMap;
      },
      {}
    );
    setVisibilityMap(newVisibiltyMap);
  };

  const onAddFilter = (newQueryFilter: QueryFilter<F>) => {
    setQueryFilters((filters) => [
      newQueryFilter,
      ...filters.filter((qf) => !isSameQueryFilter(qf, newQueryFilter)),
    ]);
    setFilters(filters.concat(newQueryFilter));
  };
  const onRemoveQueryFilter = (toRemove: QueryFilter<F>) => {
    setQueryFilters((queryFilters) =>
      queryFilters.filter((qf) => !isSameQueryFilter(toRemove, qf))
    );
    setFilters((queryFilters) => queryFilters.filter((qf) => !isSameQueryFilter(toRemove, qf)));
  };

  const onUpdateParams = (newQueryFilter: QueryFilter<F>[]) => {
    setCategoryFilters(newQueryFilter);
    setFilters(queryFilters.concat(newQueryFilter).concat(dateFilters));
  };

  const onUpdateDateParams = (newDateFilter: QueryFilter<F>[]) => {
    setDateFilters(newDateFilter);
    setFilters(queryFilters.concat(categoryFilters).concat(newDateFilter));
  };

  const hasLoadedData = data != null;
  const totalRows = hasLoadedData ? data.count : 0;
  useEffect(() => {
    shareTotalRowsCallback?.(totalRows);
  }, [shareTotalRowsCallback, totalRows]);

  // This allow us to refetch data when some condition is matched on the data results
  useEffect(() => {
    let intervalId: any;
    if (data && refetchCondition && refetchCondition(data)) {
      intervalId = setInterval(() => {
        refetch && refetch();
      }, refetchInterval);
    }

    if (data?.results.length === 0) {
      if (collapsable) {
        setCollapsed(true);
      }
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [data, refetch, refetchCondition]);

  const fillBlankSpace = tableSize === "big";
  const isSmallScreen = ScreenSmallerThen("1100px");
  const bigFixedHeightSize = isSmallScreen ? "100%" : bigFixedHeight;

  const showheaderComponents =
    !!filterOptions ||
    !!secondComponent ||
    !hideColumnVisibility ||
    !!filtersVisibility ||
    !dateFilters ||
    titleInline;

  useEffect(() => {
    if (totalRows !== 0 && expandOnResults) {
      setCollapsed(false);
    }
  }, [totalRows]);

  return hideOnEmpty && totalRows === 0 ? null : (
    <Box
      id={id}
      sx={{
        maxWidth: "100%",
        width: "100%",
        height: fillBlankSpace ? "100%" : "unset",
        overflow: "visible",
      }}
    >
      <Box height={bigFixedHeight ? bigFixedHeightSize : fillBlankSpace ? "100%" : "unset"}>
        <Box
          paddingBottom={totalRows === 0 && !collapsed ? "10px" : "0px"}
          borderRadius={showBottomBorderOnly ? "0px" : "6px"}
          border={showBottomBorderOnly ? "" : "1px solid"}
          borderTop={(showheaderComponents || !!title) && borderTop ? 1 : 0}
          borderBottom={1}
          borderRight={borderRight ? 1 : 0}
          minHeight="100%"
          maxHeight="100%"
          overflow="auto"
          borderColor={theme.palette.custom.primaryBorder}
          bgcolor={theme.palette.custom.primaryBackground}
          sx={sx}
          gap="10px"
        >
          {!titleInline && title && (
            <Box
              display="flex"
              padding={collapsed ? "10px" : "10px 10px 10px 10px"}
              flexDirection="row"
              alignItems="center"
              gap="10px"
              sx={{
                cursor: collapsed ? "pointer" : "default",
              }}
              onClick={() => {
                if (collapsable) {
                  setCollapsed(false);
                }
              }}
            >
              {collapsable && (
                <Box
                  sx={{ cursor: "pointer" }}
                  onClick={(event) => {
                    event.stopPropagation();
                    setCollapsed(!collapsed);
                  }}
                  alignItems="center"
                  display="flex"
                >
                  <NavbarIcon variant={collapsed ? "chevron-down-sm" : "chevron-up-sm"} />
                </Box>
              )}
              <Typography variant="h3">{title}</Typography>
              {titleRightComponent}
            </Box>
          )}
          {!collapsed && (
            <>
              {!hasLoadedData || isLoading ? (
                <APITableLoader
                  columnsVisibility={columnsVisibility}
                  hideColumnVisibility={hideColumnVisibility}
                  tableSize={tableSize}
                  secondComponent={!!secondComponent}
                  tableSearch={!!filterOptions}
                  filtersVisibility={filtersVisibility}
                  dateFilters={!!dateFilters}
                />
              ) : (
                <>
                  {showheaderComponents && (
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      padding="10px"
                      alignItems="center"
                      paddingTop={!!title ? "0px" : "10px"}
                      paddingLeft={titleInline ? "0px" : "10px"}
                      gap="10px"
                      flexWrap="wrap"
                      // alignItems="center"
                    >
                      {titleInline && title && (
                        <Typography variant="h3" padding="10px 10px 0 10px">
                          {title}
                        </Typography>
                      )}
                      {filterOptions?.length && (
                        <TableSearch
                          filterOptions={filterOptions}
                          useGetData={useGetData}
                          onAddFilter={onAddFilter}
                          queryParams={_queryParams}
                          displayTags={displayTags}
                          maxWidth={searchBarMaxWidth}
                        />
                      )}
                      <Box
                        display="flex"
                        gap={hideColumnVisibility ? "0px" : "10px"}
                        flexWrap="wrap"
                        flexGrow={3}
                        justifyContent={isSmallScreen ? "flex-start" : "space-between"}
                      >
                        <Box
                          display="flex"
                          gap={hideColumnVisibility ? "0px" : "10px"}
                          flexWrap="wrap"
                          justifyContent="flex-start"
                        >
                          {!hideColumnVisibility && (
                            <ColumnVisibility
                              columns={columnsVisibility}
                              changeVisibility={changeVisibility}
                            />
                          )}
                          {dateFiltersData && (
                            <DateFilter
                              dateFilters={dateFiltersData}
                              onUpdate={onUpdateDateParams}
                              setDateFilters={setDateFiltersData}
                            />
                          )}
                          {filtersVisibility && filterData && filterType && filterName && (
                            <Filter
                              filterData={filterData}
                              onUpdateParams={onUpdateParams}
                              filterType={filterType}
                              filterName={filterName}
                              setDeselectAll={(deselectAll: boolean) => setDeselectAll(deselectAll)}
                              setFilterData={setFilterData}
                            />
                          )}
                        </Box>
                        {secondComponent}
                      </Box>
                    </Box>
                  )}
                  {queryFilters && queryFilters.length > 0 && (
                    <Box marginBottom="10px" marginX="10px">
                      <QueryFilterControl
                        queryFilters={queryFilters}
                        onRemoveQueryFilter={onRemoveQueryFilter}
                      />
                    </Box>
                  )}
                  {collapsableTableComponent && (
                    <CollapsableComponent
                      onChange={() => setCollapsedTable(!collapsedTable)}
                      visible={!collapsedTable}
                      label={`${data?.count} ${collapsableComponenetLabel}`}
                      labelColor={collapsableComponentLabelColor}
                      showIcon={data.results.length > 0}
                    >
                      {collapsableTableComponent}
                    </CollapsableComponent>
                  )}
                  <Table
                    visible={showTable}
                    smallEmptyHeight={smallEmptyHeight}
                    columnsVisibility={columnsVisibility}
                    isLoading={!hasLoadedData || isLoading}
                    rows={
                      !showNoData ? (!deselectAll ? (hasLoadedData ? data.results : null) : []) : []
                    }
                    rowOnClick={rowOnClick}
                    emptyTableComponent={emptyTableComponent}
                    onShowAllColumns={showAllColumns}
                    expandable={expandable}
                    tableExpandableComponent={tableExpandableComponent}
                    showFirstExpanded={showFirstExpanded}
                    minEmptyHeight={minEmptyHeight}
                  />
                </>
              )}
            </>
          )}
        </Box>
      </Box>
      {!collapsed && showPagination && (
        <Box paddingTop="15px" display="flex" justifyContent="end">
          <TablePagination
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            totalCount={!deselectAll ? totalRows : 0}
            setRowsPerPage={setRowsPerPage}
            nextCursor={data?.next}
            previousCursor={data?.previous}
            setCursor={(cursor) =>
              setFilters(
                filters.concat({
                  filterOption: { label: "cursor", value: "cursor" as F },
                  value: cursor,
                })
              )
            }
          />
        </Box>
      )}
    </Box>
  );
};
