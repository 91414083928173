import { Box, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { IconButton } from "../atoms/IconButton";
import { NavbarIcon } from "../atoms/navbar/Icon";
import { DisplayHyperparameterValue } from "../components/DisplayHyperparameterValue";
import { Table } from "../components/Table";
import { FilterOption, QueryFilter } from "../models/types";
import { isSameQueryFilter } from "../utilities/common";
import { isJSON } from "../utilities/UIHelper";
import { IColumnVisibility } from "./ColumnVisibility";
import { JSONView } from "./JSONView";
import { QueryFilterControl } from "./QueryFilterControl";
import { ShowMore } from "./ShowMore";
import { TableSearch } from "./TableSearch";
import { TableTextWithEllipsis } from "./TableTextWithEllipsis";

function isType1Json(parsed: any): boolean {
  if (!parsed || typeof parsed !== "object" || Array.isArray(parsed)) return false;

  return Object.values(parsed).some((val: any) => {
    if (typeof val !== "object" || val === null || Array.isArray(val)) {
      return false;
    }
    return "type" in val && "comment" in val;
  });
}

function isType2Json(parsed: any): boolean {
  if (!parsed || typeof parsed !== "object" || Array.isArray(parsed)) return false;
  return Object.values(parsed).every(
    (val) =>
      typeof val === "string" ||
      val === null ||
      typeof val === "number" ||
      typeof val === "boolean" ||
      typeof val === "object"
  );
}

function isType3Json(parsed: any): boolean {
  if (!Array.isArray(parsed)) return false;
  // return parsed.every(
  //   (item) =>
  //     item && typeof item === "object" && !Array.isArray(item) && "key" in item && "value" in item
  // );
  return parsed.every((item) => item && typeof item === "object" && !Array.isArray(item));
}

type Type1Row = {
  id: string;
  name: string;
  type?: string;
  description?: string;
};

type Type2Row = {
  id: string;
  name: string;
  value?: string;
};

type Type3Row = {
  id: string;
  [key: string]: any;
};

type UniversalRow = {
  id: string;
  name?: string;
  type?: string;
  description?: string;
  value?: string;
  [key: string]: any;
};

type JSONTableViewProps = {
  json: string;
  editing?: boolean;
  setOpen?: () => void;
  expandable?: boolean;
  title?: string;
  hideSearch?: boolean;
};

export const JSONTableView = ({
  json,
  editing = false,
  title,
  hideSearch = false,
  expandable = false,
  setOpen = () => {},
}: JSONTableViewProps) => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState<boolean>(false);
  let parsed: any = null;
  if (typeof json === "string" && isJSON(json)) {
    try {
      parsed = JSON.parse(json);
    } catch (error) {
      parsed = null;
    }
  }

  const isT1 = parsed && isType1Json(parsed);
  const isT2 = parsed && isType2Json(parsed);
  const isT3 = parsed && isType3Json(parsed);

  let rows: UniversalRow[] = [];
  let columnVisibility: IColumnVisibility<UniversalRow>[] = [];
  let FILTER_OPTIONS: FilterOption<any>[] = [];

  if (isT1) {
    const entries = Object.entries(parsed);
    rows = entries.map(([key, val], index) => {
      return {
        id: String(index),
        name: key,
        type: (val as { type?: string }).type ?? "",
        description: (val as { comment?: string }).comment ?? "",
      } as Type1Row;
    });
    columnVisibility = [
      {
        field: "name",
        headerName: "Name",
        visible: true,
        columnMinWidth: 180,
        renderer: (row) => <TableTextWithEllipsis value={(row as Type1Row).name} />,
      },
      {
        field: "type",
        headerName: "Type",
        visible: true,
        columnMinWidth: 160,
        renderer: (row) => <DisplayHyperparameterValue value={(row as Type1Row).type} />,
      },
      {
        field: "description",
        headerName: "Description",
        visible: true,
        columnMinWidth: 220,
        renderer: (row) => (
          <TableTextWithEllipsis value={(row as Type1Row).description || ""} expanded />
        ),
      },
    ];
    FILTER_OPTIONS = [
      { label: "Name", value: "name" },
      { label: "Type", value: "type" },
      { label: "Description", value: "description" },
    ];
  } else if (isT2) {
    const entries = Object.entries(parsed);
    rows = entries.map(([key, val], index) => {
      try {
        return {
          id: String(index),
          name: key,
          value: typeof val === "object" ? JSON.stringify(val) : String(val ?? ""),
        } as Type2Row;
      } catch (e) {
        return {
          id: String(index),
          name: key,
          value: "Failed to parse value",
        } as Type2Row;
      }
    });
    columnVisibility = [
      {
        field: "name",
        headerName: "Name",
        visible: true,
        columnMinWidth: 180,
        renderer: (row) => <TableTextWithEllipsis value={(row as Type2Row).name} />,
      },
      {
        field: "value",
        headerName: "Value",
        visible: true,
        columnMinWidth: 220,
        renderer: (row) => <DisplayHyperparameterValue value={(row as Type2Row).value} />,
      },
    ];
    FILTER_OPTIONS = [
      { label: "Name", value: "name" },
      { label: "Value", value: "value" },
    ];
  } else if (isT3) {
    const arr = parsed as Record<string, any>[];
    rows = arr.map((item, index) => ({
      id: String(index),
      ...item,
    }));

    const allKeys = new Set<string>();
    for (const obj of arr) {
      for (const k of Object.keys(obj)) {
        if (k !== "id") allKeys.add(k);
      }
    }
    const arrayOfKeys = Array.from(allKeys);
    columnVisibility = arrayOfKeys.map((fieldName) => ({
      field: fieldName,
      headerName: fieldName,
      visible: true,
      columnMinWidth: 180,
      renderer: (row) => <DisplayHyperparameterValue value={String(row[fieldName] ?? "")} />,
    }));

    FILTER_OPTIONS = arrayOfKeys.map((fieldName) => ({
      label: fieldName,
      value: fieldName,
    }));
  }

  const isKnownType = isT1 || isT2 || isT3;
  const hasParsedData =
    parsed &&
    ((Array.isArray(parsed) && parsed.length > 0) ||
      (typeof parsed === "object" && Object.keys(parsed).length > 0));

  const [queryFilters, setQueryFilters] = useState<QueryFilter<any>[]>([]);

  const [selectedView, setSelectedView] = useState<"table" | "json">("table");
  const [data, setData] = useState<UniversalRow[]>(rows);

  const onAddFilter = (newQueryFilter: QueryFilter<any>) => {
    setQueryFilters((filters) => [
      newQueryFilter,
      ...filters.filter((qf) => !isSameQueryFilter(qf, newQueryFilter)),
    ]);
  };
  const onRemoveQueryFilter = (toRemove: QueryFilter<any>) => {
    setQueryFilters((queryFilters) =>
      queryFilters.filter((qf) => !isSameQueryFilter(toRemove, qf))
    );
  };

  useEffect(() => {
    if (queryFilters.length) {
      const query = queryFilters.map((queryFilter) => queryFilter.value);
      const filter = rows.filter((row: any) => query.includes(row.name));
      setData([...filter]);
    } else {
      setData(rows ? [...rows] : []);
    }
  }, [queryFilters]);

  try {
    if (!isKnownType || !hasParsedData) {
      return <ShowMore description={json} />;
    }

    return (
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        border={`1px solid ${theme.palette.custom.primaryBorder}`}
        borderRadius="6px"
      >
        <Box
          width="100%"
          padding="10px"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          {expandable && (
            <Box
              display="flex"
              alignItems="center"
              gap="10px"
              onClick={() => setExpanded(!expanded)}
              sx={{ cursor: "pointer" }}
            >
              <NavbarIcon variant={expanded ? "chevron-up-sm" : "chevron-down-sm"} />
              {title && <Typography variant="h4">{title}</Typography>}
            </Box>
          )}
          {!hideSearch && (
            <TableSearch filterOptions={FILTER_OPTIONS} onAddFilter={onAddFilter} rows={rows} />
          )}
          {((expandable && expanded) || !expandable) && (
            <Box display="flex" gap="10px" alignItems="center">
              <IconButton
                color={theme.palette.custom.hyperlink}
                height="16px"
                width="16px"
                onClick={() => navigator.clipboard.writeText(json)}
                variant="copy"
                padding="7px"
              />
              {editing && (
                <IconButton
                  color={theme.palette.custom.hyperlink}
                  height="16px"
                  width="16px"
                  onClick={setOpen}
                  variant="edit-icon"
                  padding="7px"
                />
              )}
              <Box display="flex">
                <Box
                  display="flex"
                  padding="10px 8px"
                  alignItems="center"
                  gap="10px"
                  border={`1px solid ${
                    selectedView === "table"
                      ? theme.palette.custom.hyperlink
                      : theme.palette.custom.grey[500]
                  }`}
                  bgcolor={
                    selectedView === "table"
                      ? theme.palette.custom.secondaryBackground
                      : theme.palette.custom.primaryBackground
                  }
                  sx={{
                    ":hover": {
                      cursor: "pointer",
                    },
                    borderTopLeftRadius: "3px",
                    borderBottomLeftRadius: "3px",
                  }}
                  onClick={() => setSelectedView("table")}
                >
                  <Typography
                    variant="h5"
                    color={
                      selectedView === "table"
                        ? theme.palette.custom.hyperlink
                        : theme.palette.custom.primaryTypography
                    }
                  >
                    Table View
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  padding="10px 8px"
                  alignItems="center"
                  gap="10px"
                  border={`1px solid ${
                    selectedView === "json"
                      ? theme.palette.custom.hyperlink
                      : theme.palette.custom.grey[500]
                  }`}
                  borderLeft="0px"
                  bgcolor={
                    selectedView === "json"
                      ? theme.palette.custom.secondaryBackground
                      : theme.palette.custom.primaryBackground
                  }
                  sx={{
                    ":hover": {
                      cursor: "pointer",
                    },
                    borderTopRightRadius: "3px",
                    borderBottomRightRadius: "3px",
                  }}
                  onClick={() => setSelectedView("json")}
                >
                  <Typography
                    variant="h5"
                    color={
                      selectedView === "json"
                        ? theme.palette.custom.hyperlink
                        : theme.palette.custom.primaryTypography
                    }
                  >
                    JSON View
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
        {queryFilters && queryFilters.length > 0 && (
          <Box margin="0px 10px 10px 10px">
            <QueryFilterControl
              queryFilters={queryFilters}
              onRemoveQueryFilter={onRemoveQueryFilter}
            />
          </Box>
        )}
        {((expandable && expanded) || !expandable) && (
          <>
            {selectedView === "json" ? (
              <Box
                display="flex"
                padding="10px"
                borderTop={`1px solid ${theme.palette.custom.primaryBorder}`}
              >
                <JSONView value={json} />
              </Box>
            ) : (
              <Box width="100%">
                <Table
                  columnsVisibility={columnVisibility}
                  rows={data}
                  smallEmptyHeight
                  minEmptyHeight
                  emptyTableComponent={
                    <Typography
                      variant="body2"
                      color={theme.palette.custom.grayTypography}
                      paddingY="10px"
                      textAlign="start"
                      width="100%"
                    >
                      No Data
                    </Typography>
                  }
                />
              </Box>
            )}
          </>
        )}
      </Box>
    );
  } catch (e) {
    return <ShowMore description={json} />;
  }
};
