import { ObjectSummary, UseCase, UseCaseFetchOptions, UseCaseSatusOption } from "../models/types";
import queryClient, { QueryKey } from "../state/QueryStore";
import { ListResponse } from "../utilities/ApiResponseHelper";
import {
  httpDeleteAuthenticated,
  httpGetAuthenticated,
  httpPatchAuthenticated,
  httpPostAuthenticated,
} from "./ApiService";

const endpoints = {
  list: () => "/use_cases",
  retrieve: (objectId: string) => `/use_cases/${objectId}`,
  summary: () => "/use_cases/summary",
};

export const getUseCases = (params: UseCaseFetchOptions) => {
  return httpGetAuthenticated<ListResponse<UseCase>>(endpoints.list(), {
    params,
  });
};

export const getUseCase = (objectId: string) => {
  return httpGetAuthenticated<UseCase>(endpoints.retrieve(objectId));
};

export const addUseCase = async (payload: {
  name: string;
  description?: string;
  related_teams?: string[];
  assignee: string | null;
  lifecycle_stage: string | null;
  potential_value: number | null;
  status: UseCaseSatusOption | null;
  template: string | null;
}) => {
  const { data } = await httpPostAuthenticated<UseCase>(endpoints.list(), payload);
  queryClient.invalidateQueries({ queryKey: [QueryKey.UseCase] });
  return data;
};

export const editUseCase = async (
  objectId: string,
  payload: {
    name?: string;
    description?: string;
    related_teams?: string[];
    status?: UseCaseSatusOption | null;
    assignee?: string | null;
    lifecycle_stage?: string | null;
    potential_value?: number | null;
  }
) => {
  const { data } = await httpPatchAuthenticated(endpoints.retrieve(objectId), payload);
  queryClient.invalidateQueries({ queryKey: [QueryKey.UseCase, objectId] });
  return data;
};

export const deleteUseCase = async (objectId: string) => {
  const resp = await httpDeleteAuthenticated(endpoints.retrieve(objectId));
  queryClient.invalidateQueries({ queryKey: [QueryKey.UseCase] });
  return resp;
};

export const getUseCaseSummary = (params: UseCaseFetchOptions) => {
  return httpGetAuthenticated<ObjectSummary>(endpoints.summary(), params);
};
