import { Box, CircularProgress, Typography, useTheme } from "@mui/material";
import { INavbarIconVariant, NavbarIcon } from "./navbar/Icon";

type IconButtonProps = {
  variant: INavbarIconVariant;
  color?: string;
  onClick: (e: any) => void;
  disabled?: boolean;
  width: string;
  selected?: boolean;
  height: string;
  padding?: string;
  strokeWidth?: string;
  title?: string;
  border?: boolean;
  loading?: boolean;
  minWidth?: string;
  minHeight?: string;
  gap?: string;
  bgcolor?: string;
  titleColor?: string;
  borderColor?: string;
};

export const IconButton = ({
  width,
  height,
  variant,
  color,
  selected,
  onClick,
  disabled,
  strokeWidth,
  padding = "2px 5px",
  title,
  border = false,
  loading = false,
  minWidth,
  minHeight,
  gap = "6px",
  bgcolor,
  titleColor,
  borderColor,
}: IconButtonProps) => {
  const theme = useTheme();
  return (
    <Box
      padding={padding ?? "2px 5px"}
      onClick={(e) => {
        e.stopPropagation();
        !disabled && onClick(e);
      }}
      display="flex"
      justifyContent="center"
      alignItems="center"
      gap={gap}
      sx={{
        cursor: "pointer",
        ...(!disabled && {
          "&:hover": {
            backgroundColor: bgcolor ? bgcolor : `${theme.palette.custom.secondaryBackground}50`,
            borderRadius: "3px",
          },
        }),
        opacity: title && disabled ? "0.5" : "1",
      }}
      border={border ? `1px solid ${borderColor ?? theme.palette.custom.tertiaryBorder}` : "none"}
      borderRadius="3px"
      minWidth={minWidth}
      minHeight={minHeight}
      bgcolor={bgcolor}
    >
      {loading ? (
        <CircularProgress size={20} />
      ) : (
        <>
          <NavbarIcon
            color={selected ? undefined : color}
            selected={selected}
            strokeWidth={strokeWidth}
            sx={{
              width: width,
              height: height,
              minHeight: height,
              minWidth: width,
            }}
            variant={variant}
          />
          {title && (
            <Typography variant="button" color={titleColor}>
              {title}
            </Typography>
          )}
        </>
      )}
    </Box>
  );
};
