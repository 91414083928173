import { Box, Typography } from "@mui/material";
import { useDepartments } from "../../hooks/useDepartments";
import { RectangularLoader } from "../skeleton-loader/RectangularLoader";
import { NameIconList } from "../../atoms/NameIcon";

type DepartmentListReadOnlyCustomFieldProps = {
  value: string[];
  emptyText?: string;
};

export const DepartmentListReadOnlyCustomField = ({
  value,
  emptyText,
}: DepartmentListReadOnlyCustomFieldProps) => {
  const { data: departments, isLoading } = useDepartments({ id: value });

  return isLoading ? (
    <RectangularLoader height="20px" width="100%" />
  ) : departments?.count && value.length > 0 ? (
    <Box display="flex" gap="5px" flexWrap="wrap">
      <NameIconList
        options={departments?.results?.map((team) => team.name) ?? []}
        maxDisplay={3}
        size="small"
        twoLetters={false}
      />
    </Box>
  ) : null;
};
