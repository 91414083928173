import { Box, Typography, useTheme } from "@mui/material";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { StringCustomField } from "../../components/CustomFieldForm";
import { useAuth } from "../../hooks/useAuth";
import { RectangularLoader } from "../../molecules/skeleton-loader/RectangularLoader";

type UserInformationProps = {
  value: string;
  onChange: (newVal: any) => void;
  required?: boolean;
  metadata?: any;
};

export const UserInformation = forwardRef((props: UserInformationProps, ref) => {
  const { value, onChange, required, metadata } = props;
  const { user } = useAuth();
  const theme = useTheme();
  const [loading, setLoading] = useState<boolean>(true);
  const [role, setRole] = useState<string>("");

  const [validationErrors, setValidationErrors] = useState({
    name: false,
    role: false,
  });

  const handleKeyChange = (newValue: string, key: string) => {
    const oldValue = JSON.parse(value || "{}");
    const newValueJson = { ...oldValue, [key]: newValue };
    onChange(JSON.stringify(newValueJson));
  };

  const getValue = (key: string) => {
    const valueJson = JSON.parse(value || "{}");
    return valueJson[key];
  };

  const roles: string[] = metadata?.roles || [
    "Product",
    "AI/ML Engineer",
    "IT, Risk & Compliance",
    "Legal & Privacy",
    "Management",
    "Other",
  ];

  useImperativeHandle(ref, () => ({
    validate: () => {
      const errors = {
        name:
          !getValue("name") || getValue("name") === "" || getValue("name").split(" ").length < 2,
        role: !getValue("role") || getValue("role") === "",
      };

      setValidationErrors(errors);

      // Return true if all fields are valid (no errors)
      return !Object.values(errors).some((hasError) => hasError);
    },
  }));

  useEffect(() => {
    if (user && value) {
      const valueJson = JSON.parse(value || "{}");
      const role = valueJson["role"];
      const name = valueJson["name"];
      if (role && role !== "" && !roles.includes(role)) {
        setRole("Other");
      } else {
        setRole(role ? role : user?.job_title);
      }
      const newValueJson = {
        name: name ? name : user?.name.trim(),
        role: role ? role : user?.job_title,
      };
      onChange(JSON.stringify(newValueJson));
      setLoading(false);
    }
  }, []);

  return loading ? (
    <Box display="flex" flexDirection="column" justifyContent="flex-start" gap="10px">
      <RectangularLoader width="100%" height="40px" />
    </Box>
  ) : (
    <Box display="flex" flexDirection="column" justifyContent="flex-start" gap="10px">
      <Typography variant="h3">Full Name</Typography>
      <StringCustomField
        required={required}
        label="Full Name"
        value={getValue("name")}
        onChange={(e) => handleKeyChange(e, "name")}
        error={validationErrors.name}
        errorMessage={"Please enter your full name"}
      />
      <Typography variant="h3">What best describes your role?</Typography>
      <Box display="flex" gap="16px" flexWrap="wrap">
        {roles.map((roleOption) => {
          return (
            <Box
              padding="8.5px 10px"
              display="flex"
              gap="10px"
              border={`1px solid ${
                role === roleOption
                  ? theme.palette.custom.blue[400]
                  : theme.palette.custom.grey[600]
              }`}
              borderRadius="3px"
              bgcolor={role === roleOption ? theme.palette.custom.blue[100] : "none"}
              onClick={() => {
                setRole(roleOption);
                if (roleOption === "Other") {
                  handleKeyChange("", "role");
                } else {
                  handleKeyChange(roleOption, "role");
                }
              }}
              sx={{
                ":hover": {
                  cursor: "pointer",
                },
              }}
            >
              <Typography variant="body1">{roleOption}</Typography>
            </Box>
          );
        })}
      </Box>
      {validationErrors.role && role !== "Other" && (
        <Typography variant="small" color={theme.palette.custom.redTypography} marginLeft="14px">
          Please select your role
        </Typography>
      )}
      {role === "Other" && (
        <>
          <Typography variant="body2">If you didn't find the option enter it manually</Typography>
          <StringCustomField
            required={required}
            label="Role"
            value={getValue("role")}
            onChange={(e) => handleKeyChange(e, "role")}
            error={validationErrors.role}
            errorMessage="Please enter role"
          />
        </>
      )}
    </Box>
  );
});
