import {
  CollectionEmbeddingMapping,
  CollectionEmbeddingMappingFetchOptions,
} from "../models/types";
import { ListResponse } from "../utilities/ApiResponseHelper";
import { httpGetAuthenticated } from "./ApiService";

const endpoints = {
  list: () => "/collection_stores_mappings",
};

export const getCollectionEmbeddingMappings = (params: CollectionEmbeddingMappingFetchOptions) => {
  return httpGetAuthenticated<ListResponse<CollectionEmbeddingMapping>>(endpoints.list(), {
    params,
  });
};
