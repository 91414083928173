import { Box, Typography } from "@mui/material";
import { RectangularLoader } from "../molecules/skeleton-loader/RectangularLoader";

export const FileDetailsModalLoader = () => {
  return (
    <>
      <Box display="flex" flexDirection="row" gap="10px" alignItems="center">
        <Typography minWidth="100px" variant="h4">
          File Type
        </Typography>
        <RectangularLoader height="14px" width="100px" />
      </Box>
      <Box display="flex" flexDirection="row" gap="10px" alignItems="center">
        <Typography minWidth="100px" variant="h4">
          File Size
        </Typography>
        <RectangularLoader height="14px" width="100px" />
      </Box>
      <Typography minWidth="100px" variant="h4">
        Description
      </Typography>
      <RectangularLoader height="100px" width="100%" />
    </>
  );
};
