import { Box, Typography, useTheme } from "@mui/material";
import { RectangularLoader } from "../../molecules/skeleton-loader/RectangularLoader";
import { AttentionCardLoader } from "./AttentionCardLoader";
import { ListViewLoader } from "../../layouts/skeleton-loaders/ListViewLoader";

export const OrganizationOverviewLoader = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        padding: "0px 0px 75px 0px",
        gap: "16px",
      }}
    >
      <Typography variant="h1" fontSize={24}>
        Welcome to Fairo
      </Typography>
      <Box
        sx={{
          maxHeight: "100%",
          gap: "16px",
          display: "flex",
        }}
      >
        <Box width="75%" display="flex" flexDirection="column" gap="16px" overflow="auto">
          <ListViewLoader tableSize="small" type="use-case" paddingBottom={false} />
          <Box
            padding="16px"
            display="flex"
            gap="16px"
            border={`1px solid ${theme.palette.custom.secondaryBorder}`}
            borderRadius="6px"
            bgcolor={theme.palette.custom.primaryBackground}
            flexWrap="wrap"
          >
            {[...Array(2)].map((_, index) => (
              <Box width="100%">
                <AttentionCardLoader />
              </Box>
            ))}
          </Box>
        </Box>
        <Box
          width="25%"
          display="flex"
          flexDirection="column"
          gap="15px"
          overflow="auto"
          height="100%"
        >
          <Box
            padding="16px"
            display="flex"
            flexDirection="column"
            gap="8px"
            border={`1px solid ${theme.palette.custom.secondaryBorder}`}
            borderRadius="6px"
            bgcolor={theme.palette.custom.primaryBackground}
          >
            <Typography variant="h2">Overview</Typography>
            {[...Array(4)].map((_, index) => (
              <RectangularLoader height="55px" width="100%" />
            ))}
          </Box>
          <AttentionCardLoader />
        </Box>
      </Box>
    </Box>
  );
};
