import { Box, useTheme } from "@mui/material";
import { RectangularLoader } from "../../molecules/skeleton-loader/RectangularLoader";

export const AttentionCardLoader = () => {
  const theme = useTheme();
  return (
    <Box
      padding="10px"
      borderRadius="3px"
      border={`1px solid ${theme.palette.custom.secondaryBorder}`}
      bgcolor={theme.palette.custom.primaryBackground}
    >
      <RectangularLoader height="55px" width="100%" />
    </Box>
  );
};
