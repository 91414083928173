import { Box, Typography, useTheme } from "@mui/material";
import { RectangularLoader } from "../../molecules/skeleton-loader/RectangularLoader";

export const UseCaseTemplateListLoader = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        padding: "0px 0px 75px 0px",
        gap: "16px",
      }}
    >
      <Box display="flex" flexDirection="column" gap="8px">
        <Typography variant="h1" fontSize="24px">
          Template Gallery
        </Typography>
        <Typography variant="body2" color={theme.palette.custom.grayTypography}>
          Build on top of exisiting use cases
        </Typography>
      </Box>
      <RectangularLoader height="35px" width="100%" />
      <Box display="flex" gap="16px" flexWrap="wrap">
        {[...Array(5)].map((_, index) => (
          <RectangularLoader height="113px" width="118px" />
        ))}
      </Box>
      <Box display="flex" gap="16px" flexWrap="wrap">
        {[...Array(6)].map((_, idx) => (
          <Box display="flex" flex="1 1 40%">
            <RectangularLoader key={idx} width="100%" height="168px" />
          </Box>
        ))}
      </Box>
    </Box>
  );
};
