export type DefaultActions = "list" | "write" | "delete" | "retrieve";

type UserAccessPermissionAction = {
  [K in DefaultActions]: boolean; // Ensures default actions exist
} & {
  [key: string]: boolean; // Allows additional custom actions
};

type UserAccessPermissionObject = {
  actions: UserAccessPermissionAction;
  resources?: string[];
};

export type UserAccessPermissionSubject =
  | "workflows"
  | "agents"
  | "audits"
  | "reports"
  | "overview"
  | "users"
  | "knowledge_store"
  | "roles"
  | "iam_policies"
  | "integrations"
  | "use_cases"
  | "vendors"
  | "assessments"
  | "frameworks"
  | "library"
  | "risk_scenarios"
  | "evidence_tests"
  | "policies"
  | "documentation"
  | "models"
  | "datasets"
  | "external_resources"
  | "test_runs"
  | "test_cases"
  | "metrics"
  | "mlflow";

export type UserAccessPermission = {
  [K in UserAccessPermissionSubject]?: UserAccessPermissionObject;
};

type HasPermissionProps = {
  permissions: UserAccessPermission;
  action: DefaultActions;
  subject: keyof UserAccessPermission;
};

export function hasPermission({ permissions, action, subject }: HasPermissionProps): boolean {
  const userPermissions = permissions?.[subject];
  if (!userPermissions) return false; // No permissions for this resource
  const { actions, resources } = userPermissions;
  if (!actions[action]) {
    return false;
  }
  // @TODO Add logic to check by resource
  // return resources.includes("*") || resources.length > 0;
  return true;
}

type HasSomePermissionProps = {
  permissions: UserAccessPermission;
  action: DefaultActions;
  subjects: Array<keyof UserAccessPermission>;
};

export function hasSomePermission({ permissions, action, subjects }: HasSomePermissionProps) {
  return subjects.some((subject: keyof UserAccessPermission) =>
    hasPermission({ permissions, action, subject })
  );
}
