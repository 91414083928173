import { LoadingButton } from "@mui/lab";
import { Box, Step, StepLabel, Stepper, Typography, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IconButton } from "../../atoms/IconButton";
import { InputOnFocus } from "../../atoms/InputOnFocus";
import { navigateToUseCase } from "../../components/UseCaseNavigator";
import { useQuestionnaire } from "../../hooks/useQuestionnaires";
import { UseCaseWizardLoader } from "../../layouts/skeleton-loaders/UseCaseWizardLoader";
import { CustomAnswer, UseCase } from "../../models/types";
import ErrorBoundaryWrapper from "../../molecules/ErrorHandling";
import { QuestionnaireProgressBar } from "../../molecules/impact-assessment/QuestionnaireProgressBar";
import { WizardQuestionDisplay } from "../../molecules/impact-assessment/WizardQuestionDisplay";
import { setQuickLinkCompleted } from "../../services/IntercomService";
import { deleteUseCase, editUseCase } from "../../services/UseCaseService";
import { getDefaultCustomFieldStartValue, ScreenSmallerThen } from "../../utilities/UIHelper";
import { ImpactAssessmentSectionMenuOption } from "../impact-assessment/Sidebar/ImpactAssessmentSectionMenuOption";

type UseCaseWizardProps = {
  useCase: UseCase;
  templateId: string;
};

type QuestionIndexBySection = {
  [key: string]: {
    value: number;
  };
};

export const UseCaseWizard = (props: UseCaseWizardProps) => {
  const { useCase, templateId } = props;
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();

  const { data: questionnaire, isLoading: isLoadingQuestionnaire } = useQuestionnaire(templateId);
  const [questionIndex, setQuestionIndex] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [answerLoading, setAnswersLoading] = useState<boolean>(true);

  const [sections, setSections] = useState<string[]>([]);
  const questionFieldRef = useRef();
  const [answers, setAnswers] = useState<CustomAnswer[]>([]);
  const [hasQuestionAnswer, setHasQuestionAnswer] = useState<boolean>(false);

  const currentStepsBySection = questionnaire?.questions
    ?.filter((q) => q.navigation_tag === questionnaire.questions[questionIndex].navigation_tag)
    .map((q) => q.sub_section);
  const [firstQuestionIndexBySection, setFirstQuestionIndexBySection] =
    useState<QuestionIndexBySection>({});
  const [completed, setCompleted] = useState<boolean>(false);
  const [quickLink, setQuickLink] = useState<string | null>(null);
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    if (questionnaire) {
      const uniqueSections = Array.from(
        new Set(questionnaire.questions.map((q) => q.navigation_tag ?? q.title))
      );
      const sectionIndexes: Record<string, { value: number }> = {};
      questionnaire.questions.forEach((item, index) => {
        if (item.navigation_tag) {
          if (!(item.navigation_tag in sectionIndexes)) {
            sectionIndexes[item.navigation_tag] = { value: index };
          }
        }
      });
      setFirstQuestionIndexBySection(sectionIndexes);
      setSections(uniqueSections);
      setAnswers(
        questionnaire.questions?.map((question: any) => ({
          questionnaire: questionnaire.id,
          question: question?.ref ?? question.title,
          field_type: question.type,
          answer: getDefaultCustomFieldStartValue(question.type),
          status: "DRAFT",
          related_object_id: useCase.id,
          related_object_type: "Use Case",
          action_metadata: question.metadata,
        }))
      );
      setAnswersLoading(false);
    }
    setCompleted(false);
  }, [questionnaire]);

  useEffect(() => {
    try {
      if (queryParams.has("quick_link")) {
        const quickLinkId = queryParams.get("quick_link");
        if (quickLinkId) {
          setQuickLink(quickLinkId);
        }
      }
    } catch (e) {}
  }, [queryParams]);

  const handleChange = (value: string | null, models?: string) => {
    // setValueError(false);
    const newAnswers = answers.map((answer, index) => {
      if (index === questionIndex) {
        return {
          ...answer,
          answer: typeof value === "string" ? value : JSON.stringify(value),
        };
      }
      if (models && answer.question === "use_case_models") {
        return {
          ...answer,
          answer: models,
        };
      }
      return answer;
    });
    if (
      newAnswers.some(
        (answer) =>
          answer.answer && answer.answer !== getDefaultCustomFieldStartValue(answer.field_type)
      )
    ) {
      setHasQuestionAnswer(true);
    }
    setAnswers(newAnswers);
  };

  const onFinish = async () => {
    try {
      if (useCase) {
        await editUseCase(useCase.id, { status: "OK" });
        navigateToUseCase({
          navigate,
          useCaseId: useCase?.id,
          useCaseNumber: useCase?.use_case_num,
        });
      }
      if (quickLink) {
        setQuickLinkCompleted(quickLink);
      }
    } catch {}
  };

  const isSmallScreen = ScreenSmallerThen();

  return questionnaire && !answerLoading ? (
    <ErrorBoundaryWrapper>
      <Box display="flex" flexDirection="column" minHeight="100vh">
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          bgcolor={theme.palette.custom.primaryBackground}
          alignItems="center"
          padding="20px"
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="flex-start"
            flexGrow={1}
            justifyContent="flex-start"
          >
            <IconButton
              variant="left-arrow"
              height="20px"
              width="20px"
              color={theme.palette.custom.secondaryTypography}
              onClick={async () => {
                if (!hasQuestionAnswer) {
                  await deleteUseCase(useCase?.id);
                  navigate("/oversight/use-cases");
                }
                if (useCase.status === "DRAFT") {
                  navigate("/oversight/use-cases");
                } else {
                  navigateToUseCase({
                    navigate,
                    useCaseId: useCase.id,
                    useCaseNumber: useCase.use_case_num,
                  });
                }
              }}
            />
            <Box display="flex" flexDirection="column" flexGrow={1} gap="5px">
              <InputOnFocus
                value={useCase.name}
                onChange={async (newName: string) => {
                  await editUseCase(useCase.id, { name: newName });
                }}
              />
              <Typography variant="body2">Use Case-{useCase?.use_case_num}</Typography>
            </Box>
          </Box>
        </Box>
        <QuestionnaireProgressBar
          rounded={false}
          questionIndex={questionIndex}
          questionnaireLength={questionnaire?.questions.length}
        />
        <Box display="flex" flexDirection="row" flexGrow={1}>
          {!isSmallScreen && (
            <Box
              display="flex"
              maxWidth="235px"
              minWidth="235px"
              minHeight="100%"
              flexGrow={1}
              padding="20px"
              gap="5px"
              borderRight={`1px solid ${theme.palette.custom.secondaryBorder}`}
              flexDirection="column"
              bgcolor={theme.palette.custom.primaryBackground}
            >
              {sections?.map((question, index) => (
                <ImpactAssessmentSectionMenuOption
                  // variant="potential-risks"
                  key={index}
                  name={question}
                  onClick={() => setQuestionIndex(firstQuestionIndexBySection[question].value)}
                  iconColor={
                    question === questionnaire.questions[questionIndex].navigation_tag
                      ? theme.palette.custom.navbarIcon
                      : undefined
                  }
                  strokeWidth="1.5"
                  selected={question === questionnaire.questions[questionIndex].navigation_tag}
                />
              ))}
            </Box>
          )}
          <Box
            display="flex"
            flexDirection="column"
            gap="16px"
            flexGrow={1}
            justifyContent="center"
            alignItems="center"
            padding="20px"
            width="100vw"
          >
            {completed ? (
              <Box
                height="100%"
                maxWidth="800px"
                display="flex"
                flexDirection="column"
                gap="24px"
                justifyContent="start"
                paddingTop="80px"
              >
                <Box display="flex" flexDirection="column" gap="4px">
                  <Typography variant="h1">Finish Use Case Setup</Typography>
                  <Typography variant="body2">
                    You're all set! Based on your approvals, we'll proceed with analyzing the
                    selected resources to enhance your use case. You can review or update these
                    approvals anytime.
                  </Typography>
                </Box>
                <Box display="flex">
                  <LoadingButton variant="contained" onClick={onFinish}>
                    Finish
                  </LoadingButton>
                </Box>
              </Box>
            ) : (
              <Box
                display="flex"
                flexDirection={"column"}
                gap="16px"
                flexGrow={1}
                width="100%"
                alignItems="center"
              >
                <Box display="flex" flexDirection="row" justifyContent="center">
                  <Stepper
                    activeStep={currentStepsBySection?.indexOf(
                      questionnaire.questions[questionIndex].sub_section
                    )}
                  >
                    {currentStepsBySection?.map((label, index) => {
                      return (
                        <Step
                          active={label === questionnaire.questions[questionIndex].sub_section}
                          key={index}
                        >
                          <StepLabel
                            sx={{
                              textAlign: "center",
                            }}
                            color={theme.palette.custom.blueTypography}
                          >
                            {label}
                          </StepLabel>
                        </Step>
                      );
                    })}
                  </Stepper>
                </Box>
                <Box
                  display="flex"
                  flexDirection={"column"}
                  gap="16px"
                  flexGrow={1}
                  width="100%"
                  maxWidth="800px"
                >
                  <WizardQuestionDisplay
                    questionnaire={questionnaire}
                    question={questionnaire?.questions[questionIndex]}
                    questionIndex={questionIndex}
                    answer={answers[questionIndex]}
                    onChange={handleChange}
                    valueError={false}
                    relatedObjectId={useCase.id}
                    handleNext={() => {
                      if (questionIndex === questionnaire?.questions.length - 1) {
                        setCompleted(true);
                      } else {
                        // @TODO remove this hardcode
                        if (
                          questionnaire?.questions[questionIndex].ref ===
                            "use_case_documentation_options" &&
                          answers[questionIndex].answer === JSON.stringify([])
                        ) {
                          return setQuestionIndex(questionIndex + 2);
                        }
                        setQuestionIndex(questionIndex + 1);
                      }
                    }}
                    loading={loading}
                    handlePrevious={() => setQuestionIndex(questionIndex - 1)}
                    relatedObjectName={useCase.name}
                  />
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </ErrorBoundaryWrapper>
  ) : (
    <UseCaseWizardLoader />
  );
};
