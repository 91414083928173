import { Box, Stack, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { NavbarIcon } from "../../atoms/navbar/Icon";
import { SmallModal } from "../../components/SmallModal";
import { useOrganizationContext } from "../../context/OrganizationContext";
import { useAuth } from "../../hooks/useAuth";
import { useCollectionEmbeddingMapping } from "../../hooks/useCollectionEmbeddingMapping";
import { CollectionEmbeddingMapping, CollectionStore } from "../../models/types";
import { IColumnVisibility } from "../../molecules/ColumnVisibility";
import { EmptyTable } from "../../molecules/EmptyTable";
import { FairoUser } from "../../molecules/FairoUser";
import { JSONTableView } from "../../molecules/JSONTableView";
import { TableTextWithEllipsis } from "../../molecules/TableTextWithEllipsis";
import { navigateToObject } from "../../utilities/GenericNavigateHelper";
import { isFairoOrganization } from "../../utilities/UIHelper";
import { APITable } from "../APITable";
import { FileDetailsModal } from "./FileDetailsModal";

type CollectionStoreModalProps = {
  /** The associated metric result */
  collection: CollectionStore;
  /** Should the modal be open? */
  open: boolean;
  /** Callback to close the modal */
  onClose: () => void;
};

export const CollectionStoreModal = (props: CollectionStoreModalProps) => {
  const { open, onClose, collection } = props;

  const { organization } = useAuth();
  const theme = useTheme();
  const navigate = useNavigate();
  const { setSelectedElement, setSelectedTask } = useOrganizationContext();
  const [selectedFile, setSelectedFile] = useState<string | null>(null);
  const columns: IColumnVisibility<CollectionEmbeddingMapping>[] = [
    {
      field: "details",
      headerName: "Name",
      visible: true,
      columnMinWidth: 400,
      renderer: (obj) => <TableTextWithEllipsis expanded value={obj?.details?.name} />,
    },
    {
      field: "related_object_type",
      headerName: "Type",
      visible: true,
      columnMinWidth: 150,
      columnMaxWidth: 150,
      renderer: (obj) => (
        <TableTextWithEllipsis
          expanded
          value={obj?.details?.type ? obj?.details?.type : obj.related_object_type}
        />
      ),
    },
    {
      field: "chunk_count",
      headerName: "Chunks",
      visible: true,
      columnMinWidth: 80,
      columnMaxWidth: 80,
      renderer: (obj) => <TableTextWithEllipsis expanded value={`${obj.chunk_count}`} />,
    },
  ];
  return (
    <SmallModal
      position="right"
      size="medium"
      open={open}
      onClose={() => onClose()}
      showCloseButton={false}
      customTitle={
        <Box display="flex" flexDirection="row" gap="10px" alignItems="center">
          <Box
            onClick={() => onClose()}
            sx={{ cursor: "pointer" }}
            display="flex"
            alignItems="center"
          >
            <NavbarIcon
              variant="left-arrow"
              sx={{ width: "22px", minWidth: "22px", height: "22px", minHeight: "22px" }}
            />
          </Box>
          <Typography variant="h2">{collection.name}</Typography>
        </Box>
      }
      title={collection.name}
    >
      <Stack gap="10px">
        {selectedFile && (
          <FileDetailsModal
            fileId={selectedFile}
            open={!!selectedFile}
            onClose={() => setSelectedFile(null)}
          />
        )}
        <Box display="flex" flexDirection="row" gap="10px" alignItems="center">
          <Typography minWidth="145px" variant="h3">
            Owner
          </Typography>
          {isFairoOrganization(collection.organization) ? (
            <FairoUser />
          ) : (
            <TableTextWithEllipsis value={organization?.name ?? ""} />
          )}
        </Box>
        <Box display="flex" flexDirection="row" gap="10px" alignItems="center">
          <Typography minWidth="145px" variant="h3">
            Object count
          </Typography>
          <Typography variant="body1">{collection.embedding_count}</Typography>
        </Box>
        <Typography minWidth="145px" variant="h3">
          Description
        </Typography>
        <Typography variant="body1">{collection.description}</Typography>
        {collection?.cmetadata && (
          <JSONTableView
            expandable
            title="Metadata"
            hideSearch
            json={
              typeof collection.cmetadata === "string"
                ? collection.cmetadata
                : JSON.stringify(collection.cmetadata)
            }
          />
        )}
        <APITable
          collapsable
          useGetData={useCollectionEmbeddingMapping}
          smallEmptyHeight={true}
          title="Related Objects"
          emptyTableComponent={
            <EmptyTable
              variant="organization"
              label="Related Objects"
              description={`No related objects added yet`}
            />
          }
          rowOnClick={(r) => {
            if ((r.related_object_type as any) === "File Attachment") {
              return setSelectedFile(r.related_object_id);
            }
            navigateToObject(
              navigate,
              r.related_object_type,
              r.related_object_id,
              setSelectedTask,
              setSelectedElement,
              false,
              true
            );
          }}
          hideColumnVisibility
          queryParams={{
            collection: collection.id,
          }}
          columnsVisibility={columns}
          tableSize="small"
        />
      </Stack>
    </SmallModal>
  );
};
