import { Box, useTheme } from "@mui/material";
import { NavbarIcon } from "./Icon";

type NavbarDirections = "left" | "right";
interface INavbarArrowProps {
  direction: NavbarDirections;
  onClick: () => void;
  marginLeft?: string;
  disabled?: boolean;
  circleRadius?: string;
  arrowHeight?: string;
}

const NavbarArrow = ({
  onClick,
  marginLeft,
  direction,
  disabled,
  circleRadius = "24px",
  arrowHeight = "12.8px",
}: INavbarArrowProps) => {
  const theme = useTheme();
  return (
    <Box
      onClick={() => (disabled ? {} : onClick())}
      sx={{
        borderRadius: "25px",
        color: "#FFFFFF",
        cursor: "pointer",
        backgroundColor: disabled
          ? theme.palette.custom.disabledButton
          : theme.palette.custom.navArrowBackground,
        width: circleRadius,
        height: circleRadius,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginLeft: marginLeft ?? 0,
      }}
    >
      <NavbarIcon
        variant={direction === "left" ? "left-arrow" : "right-arrow"}
        color="#FFFFFF"
        sx={{
          width: arrowHeight,
          height: arrowHeight,
          minHeight: arrowHeight,
          minWidth: arrowHeight,
        }}
      />
    </Box>
  );
};

export default NavbarArrow;
