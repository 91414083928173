import { useEffect, useState } from "react";
import { useCollectionStores } from "../../hooks/useCollectionStores";
import { CollectionStore, FieldHandlerProps } from "../../models/types";
import { Awaitable } from "../../utilities/common";
import { checkAndReturnValidJSON } from "../../utilities/UIHelper";
import { SearchableDropdown } from "../../components/SearchableDropdown";
import { RectangularLoader } from "../skeleton-loader/RectangularLoader";
import { Box } from "@mui/system";
import { DefaultBadge } from "../../atoms/StatusBadge";
import { Typography } from "@mui/material";
import { CollectionStoreModal } from "../../organisms/modals/CollectionStoreModal";

export const CollectionStoreListCustomField = (
  props: FieldHandlerProps & {
    search: (searchText: string) => Awaitable<CollectionStore[]>;
    setObjectAttributes?: (obj: any) => void;
  }
) => {
  const { value: _value, search, onChange, error, setObjectAttributes, required } = props;
  const value = checkAndReturnValidJSON(_value, []);
  const { data: items } = useCollectionStores({ id: value });
  useEffect(() => {
    if (items && items.results && items.results.length && setObjectAttributes) {
      setObjectAttributes(items.results[0] ?? {});
    }
  }, [items]);

  return (
    <SearchableDropdown
      label="Collection Store"
      required={required}
      error={error}
      multiple={true}
      value={value.length === 0 ? [] : items?.results ?? []}
      onChange={(newValue) => newValue != null && onChange(newValue.map((item) => item.id))}
      getOptionLabel={(obj) => obj.name}
      getOptions={search}
      isOptionEqualToValue={(a, b) => a.id === b.id}
      getSummaryDescription={(obj) => [obj.name, obj?.description ?? ""]}
    />
  );
};

type CollectionStoreReadOnlyCustomFieldProps = {
  value: string[];
};

export const CollectionStoreReadOnlyCustomField = ({
  value,
}: CollectionStoreReadOnlyCustomFieldProps) => {
  const { data: collectionStores, isLoading } = useCollectionStores({ id: value });
  const [selectedStore, setSelectedStore] = useState<CollectionStore | null>(null);

  return isLoading ? (
    <RectangularLoader height="20px" width="100%" />
  ) : collectionStores?.count && value.length > 0 ? (
    <Box display="flex" gap="5px" flexWrap="wrap">
      {selectedStore && (
        <CollectionStoreModal
          open={!!selectedStore}
          collection={selectedStore}
          onClose={() => setSelectedStore(null)}
        />
      )}
      {collectionStores?.results?.map((obj, index) => {
        return <DefaultBadge key={index} value={obj?.name} onClick={() => {}} />;
      })}
    </Box>
  ) : (
    <Typography variant="body2">No collection stores</Typography>
  );
};
