import { Box, useTheme } from "@mui/material";
import { useCustomFieldValues } from "../hooks/useCustomFields";
import { useMetricResults } from "../hooks/useMetricResults";
import { useMetric } from "../hooks/useMetrics";
import { checkAndReturnValidJSON } from "../utilities/UIHelper";
import { useState, useEffect } from "react";
import { evaluateColorLogic } from "../utilities/ColorLogicHelper";
import { KPIBadge } from "./TaskSuggestionDisplay";
import { RectangularLoader } from "../molecules/skeleton-loader/RectangularLoader";
import { navigateToMetricInNewTab } from "../components/TestNavigator";

type MetricKPIsProps = {
  metricId: string;
};

export const MetricKPIs = ({ metricId }: MetricKPIsProps) => {
  const theme = useTheme();
  const { data: metric, isLoading: isLoadingMetric } = useMetric(metricId, { fairo_data: true });
  const { data: metricResult, isLoading: isLoadingMetricResult } = useMetricResults({
    "metric[]": [metricId],
    page_size: 1,
  });

  const { data: colorScheme, isLoading: isLoadingColorScheme } = useCustomFieldValues(metricId, {
    "custom_field_field_key[]": ["color_scheme"],
  });

  const [loading, setLoading] = useState<boolean>(true);
  const [color, setColor] = useState<string>(theme.palette.custom.secondaryStatusColor);

  useEffect(() => {
    if (colorScheme && colorScheme.length > 0 && metricResult && metricResult.results.length > 0) {
      const metricResultValue = metricResult.results[0].value;
      const value = colorScheme[0].value;
      const rules = checkAndReturnValidJSON(value, value);
      const evaluatedColor = evaluateColorLogic(rules, metricResultValue, "Number");
      setColor(evaluatedColor ?? theme.palette.custom.secondaryStatusColor);
    }
    setLoading(false);
  }, [colorScheme, metricResult]);

  return (
    <Box display="flex">
      {loading || isLoadingMetric || isLoadingMetricResult || isLoadingColorScheme ? (
        <RectangularLoader width="156px" height="45px" />
      ) : (
        <KPIBadge
          color={color}
          label={metric?.name}
          value={
            (metricResult?.results.length ?? 0) > 0 ? metricResult?.results[0].value ?? "" : "-"
          }
          onClick={() => navigateToMetricInNewTab(metric)}
        />
      )}
    </Box>
  );
};
