import jwtDecode from "jwt-decode";
import { createContext, useContext, useState } from "react";
import { getCookie } from "../context/CookiesContext";
import { IRegisterUserData } from "../models/IRegisterUserData";
import { Organization, PluginType, UseCaseTabsOption, User } from "../models/types";
import { getAuthTokens, getUsername, signOut as signOutFromApp } from "../services/AuthService";
import { getOrganization, getUserByID, registerUser } from "../services/DataService";
import { DefaultActions, hasPermission, UserAccessPermission } from "../utilities/PermissionHelper";

interface IAuthContext {
  user: User | null;
  organization: Organization | null;
  /**undefined initially till login check is made, after that either true or false */
  loggedIn: boolean | undefined;
  tryGetUser: () => Promise<User | null>;
  signUp: (userData: IRegisterUserData) => Promise<any>;
  signOut: () => Promise<void>;
  setLoggedIn: (value: boolean) => void;
  isAuditor: () => boolean;
  permissions: UserAccessPermission | {};
  plugins: PluginType[];
  setPlugins: (plugins: PluginType[]) => void;
  setPermissions: (permissions: UserAccessPermission) => void;
  userHasPermission: (payload: {
    action: DefaultActions;
    subject: keyof UserAccessPermission;
  }) => boolean;
  userHasPluginPermission: (payload: { pluginType: PluginType }) => boolean;
  useCaseTabs: UseCaseTabsOption[];
  setUseCaseTabs: (tabs: UseCaseTabsOption[]) => void;
  userHasUseCaseTabPermission: (payload: { tab: UseCaseTabsOption }) => boolean;
}

interface IDecodedAccessToken {
  exp: number;
  iat: number;
  jti: string;
  user_id: string;
}

// Default values will be used in components not wrapped with [ProvideAuth]
// Since we wrap the entire app with [ProvideAuth], set default values as throw error.
const authContext = createContext<IAuthContext>({
  user: null,
  organization: null,
  loggedIn: undefined,
  setLoggedIn: () => {
    throw "called outside ProvideAuth";
  },
  tryGetUser: () => {
    throw "called outside ProvideAuth";
  },
  signUp: () => {
    throw "called outside ProvideAuth";
  },
  signOut: () => {
    throw "called outside ProvideAuth";
  },
  isAuditor: () => false,
  permissions: {},
  setPermissions: () => {
    throw "called outside ProvideAuth";
  },
  userHasPermission: () => {
    throw "called outside ProvideAuth";
  },
  userHasPluginPermission: () => {
    throw "called outside ProvideAuth";
  },
  plugins: [],
  setPlugins: () => {
    throw "called outside ProvideAuth";
  },
  useCaseTabs: [],
  setUseCaseTabs: () => {
    throw "called outside ProvideAuth";
  },
  userHasUseCaseTabPermission: () => {
    throw "called outside ProvideAuth";
  },
});

/** Provider hook that creates auth object and handles state */
function useProvideAuth(): IAuthContext {
  const [user, setUser] = useState<User | null>(null);
  const [organization, setOrganiation] = useState<Organization | null>(null);
  const [loggedIn, setLoggedIn] = useState<boolean | undefined>(undefined);
  const [plugins, setPlugins] = useState<PluginType[]>([]);
  const [useCaseTabs, setUseCaseTabs] = useState<UseCaseTabsOption[]>([]);
  const [permissions, setPermissions] = useState<UserAccessPermission | {}>({});
  const tryGetOrganization = async (orgId: string) => {
    try {
      const _org = await getOrganization(orgId);
      if (_org) {
        setOrganiation(_org);
        return _org;
      }
    } catch (err) {
      console.error("Error fetching organization", err);
    }
    return null;
  };

  const tryGetUser = async () => {
    const myUsername = getUsername() ?? undefined;
    try {
      const tokens = getAuthTokens();
      if (!tokens) {
        setLoggedIn(false);
        return null;
      }
      const decodedInfo = jwtDecode(tokens?.accessToken ?? "") as IDecodedAccessToken;

      const _user = await getUserByID(decodedInfo.user_id);
      if (_user) {
        setUser(_user);
        setLoggedIn(true);
        const orgId = getCookie("organization_id");
        // initiate organization fetch but don't wait
        tryGetOrganization(orgId ? orgId : _user.organization!);
        return _user;
      }
    } catch (err) {
      console.error("Error fetching user", err);
    }
    // not logged in
    setLoggedIn(false);
    return null;
  };

  const signUp = (userData: IRegisterUserData) => {
    return registerUser({
      password: userData.password,
      username: userData.username,
      first_name: userData.first_name,
      last_name: userData.last_name,
      email: userData.email.toLowerCase(),
    });
  };

  const isAuditor = () => {
    return user?.permissions === "Auditor";
  };

  const signOut = () => {
    return signOutFromApp().then(() => {
      setUser(null);
      setLoggedIn(false);
      window.location.href = "/login";
    });
  };

  const userHasPermission = ({
    action,
    subject,
  }: {
    action: DefaultActions;
    subject: keyof UserAccessPermission;
  }) => {
    return hasPermission({ permissions, action, subject });
  };

  const userHasPluginPermission = ({ pluginType }: { pluginType: PluginType }) => {
    return plugins.some((p) => p === pluginType);
  };

  const userHasUseCaseTabPermission = ({ tab }: { tab: UseCaseTabsOption }) => {
    return useCaseTabs.some((t) => t === tab);
  };

  // Return the user object and auth methods
  return {
    user,
    organization,
    loggedIn,
    tryGetUser,
    setLoggedIn,
    signUp,
    signOut,
    isAuditor,
    permissions,
    setPermissions,
    userHasPermission,
    plugins,
    setPlugins,
    userHasPluginPermission,
    useCaseTabs,
    setUseCaseTabs,
    userHasUseCaseTabPermission,
  };
}

/** Provider component that makes auth object available to any child component that calls useAuth() */
function ProvideAuth(props: { children: any }) {
  const auth = useProvideAuth();
  const { children } = props;
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

/** Hook for child components to get the auth object and re-render when it changes. */
const useAuth = () => {
  return useContext(authContext);
};

export { ProvideAuth, useAuth };
