import { useQuery } from "react-query";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { fetchWorkflows } from "../hooks/useWorkflows";
import { Workflow } from "../models/types";
import BreadcrumbsNavigator from "../molecules/BreadcrumbsNavigator";
import { ProcessGraphIcon, WorkflowIndicatorIcon } from "./AppIcons";

/** Map<pathname, state> */
let _pathStateMap: Map<
  string,
  {
    workflowId?: string;
    workflowNumber?: number;
    agentId?: string;
    agentNumber?: number;
    processGraphId?: string;
    processGraphNumber?: number;
    processNodeId?: string;
    executionPlanId?: string;
    executionPlanNumber?: number;
    edit?: boolean;
  }
> = new Map();

/**
 * Breadcrumb navigator for workflow, processes, nodes.
 * Should be used in base workflow page.
 */
const WorkflowNavigator = () => {
  let crumbs: any = [
    {
      // base route always present
      label: "Workflows",
      tooltip: "All Workflows",
      routeTo: "/workflows",
    },
  ];

  const { pathname } = useLocation();
  // '/workflows' is common to all paths so remove first two elements after splitting
  const pathSegments = pathname.split("/").slice(2);
  let workflowSegment, processSegment, nodeSegment;

  if (pathSegments.length > 0) {
    workflowSegment = pathSegments[0];
    crumbs.push({
      label: workflowSegment,
      routeTo: `/workflows/${workflowSegment}`,
      icon: <WorkflowIndicatorIcon marginRight={5} />,
    });
  }

  if (pathSegments.length > 1) {
    processSegment = pathSegments[1];
    if (processSegment !== "execution") {
      crumbs.push({
        label: processSegment,
        routeTo: `/workflows/${workflowSegment}/${processSegment}`,
        icon: <ProcessGraphIcon marginRight={5} />,
      });
    }
  }

  if (pathSegments.length > 2) {
    nodeSegment = pathSegments[2];
    crumbs.push({
      label: nodeSegment,
      routeTo: `/workflows/${workflowSegment}/${processSegment}/${nodeSegment}`,
    });
  }

  return <BreadcrumbsNavigator crumbs={crumbs} />;
};

const navigateToWorkflow = (params: {
  navigate: NavigateFunction;
  workflowId: string;
  workflowNumber: number;
  edit?: boolean;
  returnLink?: boolean;
}) => {
  const { navigate, workflowId, workflowNumber, edit, returnLink = false } = params;
  if (returnLink) {
    return `/workflows/workflow-${workflowNumber}`;
  }
  const path = `/workflows/workflow-${workflowNumber}`;
  _pathStateMap.set(path, {
    workflowId,
    edit,
  });

  navigate(path);
};

const navigateToAgent = (params: {
  navigate: NavigateFunction;
  agentId: string;
  agentNumber: number;
  edit?: boolean;
  returnLink?: boolean;
}) => {
  const { navigate, agentId, agentNumber, edit, returnLink = false } = params;
  if (returnLink) {
    return `/workflows/workflow-${agentNumber}`;
  }
  const path = `/workflows/workflow-${agentNumber}`;
  _pathStateMap.set(path, {
    agentId,
    edit,
  });

  navigate(path);
};

const getPathState = (path: string) => {
  return _pathStateMap.get(path) ?? {};
};

const WORKFLOW_NAVIGATOR_KEY = "workflow-navigator";

export const useWorkflowPathState = (path: string) => {
  const { workflowId, edit } = getPathState(path);
  // path is of the form '/workflows/workflow-12'
  const pathParts = path.split("/");
  const workflowPart = pathParts[pathParts.length - 1];
  const workflowNum = workflowPart.slice("workflow-".length);
  const navigate = useNavigate();

  return useQuery<{
    workflowId: string;
    edit?: boolean;
  }>(
    [WORKFLOW_NAVIGATOR_KEY, path],
    async () => {
      if (workflowId != null) {
        return { workflowId, edit };
      }

      const workflows = await fetchWorkflows({ workflow_num: [workflowNum] });
      const workflow = workflows.results.find((w) => `workflow-${w.workflow_num}` === workflowPart);
      if (!workflow) {
        // Bad URL. redirect to workflows page
        navigate("/workflows");
        return { workflowId: "", edit };
      }

      return { workflowId: workflow.id, edit };
    },
    { suspense: true }
  );
};

const navigateToWorkflowInNewTab = (workflow: Workflow) => {
  window.open(`/workflows/workflow-${workflow.workflow_num}`, "_blank");
};

const navigateToAgentInNewTab = (aiAgent: Workflow) => {
  window.open(`/workflows/workflow-${aiAgent.workflow_num}`, "_blank");
};

export {
  navigateToAgent,
  navigateToAgentInNewTab,
  navigateToWorkflow,
  navigateToWorkflowInNewTab,
  WorkflowNavigator,
};
