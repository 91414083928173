import {
  Questionnaire,
  QuestionnaireFetchOptions,
  QuestionnaireSection,
  QuestionnaireSectionFetchOptions,
  QuestionnaireTemplateMetadata,
  TypeformForm,
} from "../models/types";
import queryClient, { QueryKey } from "../state/QueryStore";
import { ListResponse } from "../utilities/ApiResponseHelper";
import {
  httpGetAuthenticated,
  httpPatchAuthenticated,
  httpPostAuthenticated,
  httpDeleteAuthenticated,
} from "./ApiService";

const questionnaireEndpoints = {
  questionnaires: () => "/fairo_questionnaires",
  questionnaire: (id: string) => `/fairo_questionnaires/${id}`,
  typeformForms: () => "fairo_questionnaires/forms",
  responses: (id: string) => `/fairo_questionnaires/${id}/responses`,
  sections: () => "/questionnaire_sections",
  section: (id: string) => `/questionnaire_sections/${id}`,
  review: (questionnaireId: string) => `/fairo_questionnaires/${questionnaireId}/review`,
  review_section: (sectionId: string) => `/questionnaire_sections/${sectionId}/review`,
};

export const getQuestionnaires = async (params: QuestionnaireFetchOptions) => {
  return httpGetAuthenticated<ListResponse<Questionnaire>>(
    questionnaireEndpoints.questionnaires(),
    {
      params,
    }
  );
};

export const getQuestionnaire = async (id: string) => {
  return httpGetAuthenticated<Questionnaire>(questionnaireEndpoints.questionnaire(id));
};

export const addQuestionnaire = async (payload: {
  name: string;
  description: string;
  questions: any;
  metadata: any;
  file: File | null;
  template_metadata?: QuestionnaireTemplateMetadata;
  published: boolean;
}) => {
  const formData = new FormData();
  formData.append("name", payload.name);
  formData.append("description", payload.description);
  formData.append("questions", JSON.stringify(payload.questions));
  formData.append("metadata", JSON.stringify(payload.metadata));
  formData.append("published", String(payload.published));
  if (payload?.template_metadata) {
    formData.append("template_metadata", JSON.stringify(payload.template_metadata));
  }
  if (payload.file) {
    formData.append("image", payload.file);
  }
  const { data } = await httpPostAuthenticated<Questionnaire>(
    questionnaireEndpoints.questionnaires(),
    formData
  );
  queryClient.invalidateQueries({ queryKey: [QueryKey.Questionnaire] });
  return data;
};

export const getTypeformForms = async (params: any) => {
  const { data } = await httpGetAuthenticated<TypeformForm>(
    questionnaireEndpoints.typeformForms(),
    {
      params,
    }
  );
  return data;
};

export const getQuestionnaireResponses = async (questionnaireId: string) => {
  return httpGetAuthenticated(questionnaireEndpoints.responses(questionnaireId));
};

export const editQuestionnaire = async (
  id: string,
  payload: {
    status?: string;
    assignee?: string | null;
    name?: string;
    description?: string;
    questions?: any;
    metadata?: any;
    file?: File | null;
    template_metadata?: QuestionnaireTemplateMetadata;
    published?: boolean;
  }
) => {
  const formData = new FormData();
  if (payload.name) {
    formData.append("name", payload.name);
  }
  if (payload.description) {
    formData.append("description", payload.description);
  }
  if (payload.status) {
    formData.append("status", payload.status);
  }
  if (payload.assignee) {
    formData.append("assignee", payload.assignee);
  }
  if (payload.questions) {
    formData.append("questions", JSON.stringify(payload.questions));
  }
  if (payload.metadata) {
    formData.append("metadata", JSON.stringify(payload.metadata));
  }
  if (payload.file || payload.file === null) {
    formData.append("image", payload.file ?? "");
  }
  if (payload.template_metadata) {
    formData.append("template_metadata", JSON.stringify(payload.template_metadata));
  }
  formData.append("published", String(payload.published));
  const { data } = await httpPatchAuthenticated(questionnaireEndpoints.questionnaire(id), formData);
  queryClient.invalidateQueries({ queryKey: [QueryKey.Questionnaire] });
  return data;
};

export const addQuestionnaireResponse = async (questionnaireId: string, payload: any) => {};

export const getQuestionnaireSections = async (params: QuestionnaireSectionFetchOptions) => {
  return httpGetAuthenticated<ListResponse<QuestionnaireSection>>(
    questionnaireEndpoints.sections(),
    {
      params,
    }
  );
};

export const getQuestionnaireSection = async (id: string) => {
  return httpGetAuthenticated<QuestionnaireSection>(questionnaireEndpoints.section(id));
};

export const reviewQuestionnaire = async (id: string, payload: { answers: string[] }) => {
  const { data } = await httpPostAuthenticated(questionnaireEndpoints.review(id), payload);
  queryClient.invalidateQueries({ queryKey: [QueryKey.Questionnaire, id] });
  return data;
};

export const reviewQuestionnaireSection = async (id: string, payload: { answers: string[] }) => {
  const { data } = await httpPostAuthenticated(questionnaireEndpoints.review_section(id), payload);
  queryClient.invalidateQueries({ queryKey: [QueryKey.Questionnaire, id] });
  return data;
};

export const deleteQuestionnaire = async (id: string) => {
  const { data } = await httpDeleteAuthenticated(questionnaireEndpoints.questionnaire(id));
  queryClient.invalidateQueries({ queryKey: [QueryKey.Questionnaire] });
  return data;
};
