import { Asset } from "../models/types";

export const isDocumentation = (asset: Asset) => {
  return asset?.type_name === "Documentation";
};

export const isPolicy = (asset: Asset) => {
  return asset?.type_name === "Policy";
};

export const isModel = (asset: Asset) => {
  return asset?.type_name === "Model";
};

export const isDataset = (asset: Asset) => {
  return asset?.type_name === "Dataset";
};

export const isAgent = (asset: Asset) => {
  return asset?.type_name === "Agent";
};
