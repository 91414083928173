import { MoreHoriz } from "@mui/icons-material";
import { Autocomplete, CircularProgress, InputAdornment, TextField, useTheme } from "@mui/material";
import { useMemo, useState } from "react";
import { useDebounce } from "../hooks/useDebounce";
import { APIFetchOptions, APIGetListHook, FilterOption, QueryFilter } from "../models/types";
import { ReactComponent as SearchIcon } from "../assets/general/search.svg";
import { ScreenSmallerThen } from "../utilities/UIHelper";
import { useTags } from "../hooks/useTags";

type TableSearchProps<T extends { id: string }, P extends APIFetchOptions, F> = {
  /** Hook to search the data */
  useGetData?: APIGetListHook<T, P>;
  /** params dict that is passed to the `useGetData` hook */
  queryParams?: P;
  /** Config describing which fields can be filtered on */
  filterOptions: FilterOption<F>[];
  /** Callback to add new search filter */
  onAddFilter: (data: QueryFilter<F>) => void;
  /** Data to display for the rows */
  rows?: T[];
  /** Display tags */
  displayTags?: boolean;
  /** Max Width */
  maxWidth?: string;
};

export const TableSearch = <T extends { id: string }, P extends APIFetchOptions, F>(
  props: TableSearchProps<T, P, F>
) => {
  const {
    useGetData,
    filterOptions,
    onAddFilter,
    queryParams,
    rows,
    displayTags = false,
    maxWidth,
  } = props;

  const theme = useTheme();
  const [value, setValue] = useState<string>("");

  // The filter option on which the current search is based
  // The user can focus the search on a specific field by using the "{Label}/" prefix
  // eg. the search text "Assignee/george" will focus on the filter option with "Assignee" label
  // If no specific filter option is searched then it's a global search on all options
  const specificFilterOption = filterOptions.find(({ label }) =>
    value.toLowerCase().startsWith(`${label.toLowerCase()}/`)
  );
  const searchText = (
    specificFilterOption == null ? value : value.substring(specificFilterOption.label.length + 1)
  ).trim();
  // Lags behind the live value to avoid excessive API calls while user is typing
  const [debouncedSearchText, isTyping] = useDebounce(searchText, 300);

  const searchParams = { page: 1, page_size: 5, search: debouncedSearchText, ...queryParams } as P;
  const tagsParams = { page: 1, page_size: 5, search: debouncedSearchText } as P;
  const { data, isLoading } = useGetData?.(searchParams) ?? { data: null, isLoading: false };
  const { data: tags } = useTags(tagsParams);
  const options = useMemo<QueryFilter<F>[]>(() => {
    const searchOptions = [];
    if (debouncedSearchText) {
      searchOptions.push({
        filterOption: { label: "Search", value: "search" as F },
        value: debouncedSearchText,
      });
    }

    const searchRegex = new RegExp(searchText, "gi");
    if (rows) {
      return rows.map((row) => {
        const matchedFilterOption: FilterOption<F> | undefined =
          specificFilterOption ||
          filterOptions.find((filterOption) =>
            getMatchedLabel((row as any)[filterOption.value], searchRegex)
          ) ||
          filterOptions[0];
        const matchedValue = (row as any)?.[matchedFilterOption.value];
        return {
          filterOption: matchedFilterOption,
          value: getMatchedLabel(matchedValue, searchRegex) ?? `${matchedValue}`,
        };
      });
    }
    if (data == null) {
      return [];
    }
    const dataOptions = data.results.map((match) => {
      // The filter option responsible for the match is either the specificFilterOption or the filter option that has a regex match in it's value. If nothing matches use if first filter option as default
      const matchedFilterOption: FilterOption<F> | undefined =
        specificFilterOption ||
        filterOptions.find((filterOption) =>
          getMatchedLabel((match as any)[filterOption.value], searchRegex)
        ) ||
        filterOptions[0];
      const matchedValue = (match as any)?.[matchedFilterOption.value];
      return {
        filterOption: matchedFilterOption,
        value: getMatchedLabel(matchedValue, searchRegex) ?? `${matchedValue}`,
      };
    });

    const tagOptions = tags?.results.map((tag) => ({
      filterOption: { label: "Tag", value: "tags" as F }, // Update the value property to be of type F
      value: tag.name,
    }));

    searchOptions.push(...dataOptions);

    searchOptions.push(...(displayTags ? tagOptions ?? [] : []));

    return searchOptions;
  }, [data, tags, specificFilterOption, searchText]);

  const isSmallScreen = ScreenSmallerThen("1100px");

  return (
    <Autocomplete<QueryFilter<F>>
      value={null}
      inputValue={value}
      options={options}
      filterOptions={(options) => options}
      onChange={(_, selectedFilterOption) => {
        selectedFilterOption && onAddFilter(selectedFilterOption);
      }}
      loading={isLoading}
      clearOnEscape
      clearOnBlur
      autoHighlight
      sx={{
        maxWidth: maxWidth ? maxWidth : isSmallScreen ? "100%" : "280px",
        width: "100%",
        height: "35px",
        "&.MuiAutocomplete-root .MuiOutlinedInput-root": {
          paddingLeft: "5px",
          ".MuiAutocomplete-input": {
            paddingLeft: "0px",
          },
        },
      }}
      renderInput={(inputProps) => (
        <TextField
          {...inputProps}
          placeholder="Search"
          onChange={(e) => setValue(e.target.value)}
          InputProps={{
            ...inputProps.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon
                  color={theme.palette.custom.secondaryTypography}
                  width="22px"
                  height="22px"
                />
              </InputAdornment>
            ),
          }}
        />
      )}
      getOptionLabel={(option) =>
        option.filterOption ? `${option.filterOption.label}/ ${option.value}` : option.value
      }
      popupIcon={
        isTyping ? (
          <MoreHoriz
            sx={{ width: "22px", height: "22px", color: theme.palette.custom.secondaryTypography }}
          />
        ) : isLoading ? (
          <CircularProgress sx={{ color: theme.palette.custom.secondaryTypography }} size="22px" />
        ) : null
      }
    />
  );
};

// Finds the part of `value` that matched the regex, or null if it doesn't match
const getMatchedLabel = (value: any, regex: RegExp): string | null => {
  if (value == null) return null;
  if (typeof value === "object") {
    for (const key in value) {
      if (key === "id") {
        continue;
      }
      const matched = getMatchedLabel(value[key], regex);
      if (matched != null) return matched;
    }
    return null;
  }
  const stringifiedValue = `${value}`;
  if (stringifiedValue.match(regex)) return stringifiedValue;
  return null;
};
