import { Box, Divider, useTheme } from "@mui/material";
import { RectangularLoader } from "../../molecules/skeleton-loader/RectangularLoader";
import { APITableLoader } from "../../organisms/skeleton-loaders/APITableLoader";
import { TasksColumnsVisibility } from "../../organisms/tables/tasks/TasksColumnsVisibility";
import { SingleContainerLayout } from "../../organisms/impact-assessment/SingleContainerLayout";
import SecondaryBox from "../../atoms/box/SecondaryBox";

export const UseCaseDetailLoader = () => {
  const theme = useTheme();

  return (
    <SingleContainerLayout
      topComponent={
        <Box display="flex">
          <Box
            width="100%"
            bgcolor={theme.palette.custom.primaryBackground}
            padding="10px"
            border={`1px solid ${theme.palette.custom.primaryBorder}`}
            sx={{
              borderTopRightRadius: "6px",
              borderTopLeftRadius: "6px",
              borderBottom: 0,
            }}
            alignItems="center"
            display="flex"
            flexDirection="row"
            gap="10px"
            justifyContent="space-between"
          >
            <Box
              display="flex"
              flexDirection="column"
              gap="5px"
              width="25%"
              height="100%"
              justifyContent="flex-start"
            >
              <RectangularLoader height="26px" width="100%" />
              <RectangularLoader height="20px" width="100%" />
            </Box>
            <Divider orientation="vertical" />
            <Box
              display="flex"
              flexDirection="column"
              gap="10px"
              width="50%"
              height="100%"
              justifyContent="flex-start"
            >
              <RectangularLoader height="17px" width="156px" />
              <Box display="flex" gap="10px" alignItems="center" width="100%">
                {[...Array(4)].map((_, index) => (
                  <RectangularLoader height="47px" width="156px" />
                ))}
              </Box>
            </Box>
            <Divider orientation="vertical" />
            <Box
              display="flex"
              flexDirection="row"
              gap="6px"
              justifyContent="space-between"
              flexGrow={1}
              alignItems="flex-start"
              width="25%"
              height="100%"
            >
              <RectangularLoader height="40px" width="100%" />
              <RectangularLoader height="40px" width="100%" />
            </Box>
          </Box>
        </Box>
      }
      leftComponent={
        <Box display="flex" flexDirection="column" gap="15px" padding="10px">
          <Box display="flex" flexDirection="column" gap="10px">
            <RectangularLoader width="100%" height="30px" />
            <RectangularLoader width="100%" height="30px" />
            <RectangularLoader width="100%" height="30px" />
            <RectangularLoader width="100%" height="30px" />
            <RectangularLoader width="100%" height="30px" />
            <RectangularLoader width="100%" height="30px" />
            <RectangularLoader width="100%" height="30px" />
            <RectangularLoader width="100%" height="30px" />
          </Box>
        </Box>
      }
      rightComponent={
        <Box display="flex" width="100%" flexDirection="column" gap="15px" padding="10px">
          <Box flexGrow={1} display="flex" flexDirection="column" gap="20px" padding="10px">
            <Box display="flex" flexDirection="column" gap="10px">
              <RectangularLoader width="100%" height="44px" />
              <RectangularLoader width="100%" height="44px" />
            </Box>
            <SecondaryBox padding="10px">
              <RectangularLoader width="100%" height="50px" />
            </SecondaryBox>
            <Box
              sx={{
                maxWidth: "100%",
                width: "100%",
                height: "100%",
                overflow: "visible",
              }}
            >
              <Box height={"100%"}>
                <Box
                  paddingBottom={"0px"}
                  borderRadius="6px"
                  border="1px solid"
                  minHeight="100%"
                  maxHeight="100%"
                  overflow="auto"
                  borderColor={theme.palette.custom.primaryBorder}
                  bgcolor={theme.palette.custom.primaryBackground}
                >
                  <APITableLoader
                    columnsVisibility={TasksColumnsVisibility({
                      onEdit: (taskId: string) => {},
                      showCompleted: false,
                    })}
                    tableSize="small"
                    secondComponent={true}
                    tableSearch={true}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      }
    />
  );
};
