import { Typography } from "@mui/material";
import { isArray } from "lodash";
import { forwardRef, useEffect, useState } from "react";
import {
  AssetCustomField,
  AssetListCustomField,
  BooleanCustomField,
  DateCustomField,
  JSONCustomField,
  MLFlowModelCustomField,
  NumberCustomField,
  RatingLevelCustomField,
  StringCustomField,
  StringListCustomField,
  UserCustomField,
  UserListCustomField,
} from "../components/CustomFieldForm";
import { SearchableDropdown } from "../components/SearchableDropdown";
import CKEditorField from "../components/custom-fields/CKEditorField";
import { searchActions } from "../hooks/useActions";
import {
  searchAssets as searchAssetsHook,
  searchAssetsWithParams,
  useAsset as useAssetHook,
  useAssets,
} from "../hooks/useAssets";
import {
  searchComplianceElements,
  useComplianceElement,
  useComplianceElements,
} from "../hooks/useComplianceElements";
import { searchDepartments, useDepartment, useDepartments } from "../hooks/useDepartments";
import { searchEvidenceMappings, useEvidenceMappings } from "../hooks/useEvidenceMapping";
import { useEvidenceTest } from "../hooks/useEvidenceTest";
import { searchExecutionTasks, useExecutionTask } from "../hooks/useExecutionTask";
import { useExecutionTasks } from "../hooks/useExecutionTasks";
import { useFile } from "../hooks/useFiles";
import { searchFrameworks, useFramework, useFrameworks } from "../hooks/useFramework";
import { searchImpactAssessments, useImpactAssessment } from "../hooks/useImpactAssessment";
import { searchMLFlowModels } from "../hooks/useMLFlow";
import { searchMetricResults } from "../hooks/useMetricResults";
import { searchMetrics } from "../hooks/useMetrics";
import { searchParameters, useParameter, useParameters } from "../hooks/useParameters";
import { searchPlugins, usePlugin, usePluginsPaginated } from "../hooks/usePlugins";
import { searchPolicies, usePolicies, usePolicy } from "../hooks/usePolicies";
import { searchQuestionnaires, useQuestionnaire } from "../hooks/useQuestionnaires";
import { searchReferences, useReference, useReferences } from "../hooks/useReferences";
import { searchResources, useResource, useResources } from "../hooks/useResources";
import { searchRoles } from "../hooks/useRoles";
import { searchTags } from "../hooks/useTags";
import { searchTestCases } from "../hooks/useTestCases";
import { searchTestRun } from "../hooks/useTestRuns";
import { searchUseCases } from "../hooks/useUseCases";
import { searchUsers as searchUsersHook, useUsers as useUsersHook } from "../hooks/useUsers";
import { searchWorkflows } from "../hooks/useWorkflows";
import {
  Asset,
  ComplianceFramework,
  CustomFieldType,
  QuestionChoice,
  RegistryFieldType,
  User,
  UserFetchOptions,
} from "../models/types";
import {
  ActionCustomField,
  ActionListCustomField,
} from "../molecules/custom-fields/ActionCustomField";
import { ColorSchemeCustomField } from "../molecules/custom-fields/ColorSchemeCustomField";
import {
  MetricCustomField,
  MetricListCustomField,
} from "../molecules/custom-fields/MetricCustomField";
import {
  MetricResultCustomField,
  MetricResultListCustomField,
} from "../molecules/custom-fields/MetricResultCustomField";
import { NumberWithUnitCustomField } from "../molecules/custom-fields/NumberWithUnitCustomField";
import { QuestionCustomField } from "../molecules/custom-fields/QuestionCustomField";
import { RoleCustomField, RoleListCustomField } from "../molecules/custom-fields/RoleCustomField";
import { TagCustomField, TagListCustomField } from "../molecules/custom-fields/TagListCustomField";
import {
  TestCaseCustomField,
  TestCaseListCustomField,
} from "../molecules/custom-fields/TestCaseCustomField";
import {
  TestRunCustomField,
  TestRunListCustomField,
} from "../molecules/custom-fields/TestRunCustomField";
import {
  UseCaseCustomField,
  UseCaseListCustomField,
} from "../molecules/custom-fields/UseCaseCustomField";
import {
  WorkflowCustomField,
  WorkflowListCustomField,
} from "../molecules/custom-fields/WorkflowCustomField";
import { useUsers as useUserHook } from "../services/UserSettingsService";
import { ListResponse } from "../utilities/ApiResponseHelper";
import { Awaitable } from "../utilities/common";
import { ImageCustomField } from "./AttachmentCustomField";
import { GenericCustomField, GenericListCustomField } from "./GenericCustomField";
import { AssetWithDescriptionListCustomField } from "./custom-fields/AssetWithDescriptionCustomField";
import { YesOrNoSelectField } from "./custom-fields/BooleanSelect";
import { CoWorkersAndRole } from "./custom-fields/CoWorkersAndRole";
import { DataOriginField } from "./custom-fields/DataOrigin";
import { DocumentationCustomField } from "./custom-fields/Documentation";
import { ExternalLinkListField } from "./custom-fields/ExternalLink";
import { FileAttachmentList } from "./custom-fields/FileAttachmentList";
import { FileOrLinkCustomField } from "./custom-fields/FileOrLinkCustomField";
import { IAStakeHolderListField } from "./custom-fields/IAStakeHolder";
import { JiraTicket } from "./custom-fields/JiraTicket";
import { MetricResultTestResultListCustomField } from "./custom-fields/MetricResultTestResultListCustomField";
import { MetricWithDescriptionListCustomField } from "./custom-fields/MetricWithDescriptionCustomField";
import { Models } from "./custom-fields/Models";
import { MultipleSelectionField } from "./custom-fields/MultipleSelection";
import {
  NameAndDescriptionField,
  NameAndDescriptionListField,
} from "./custom-fields/NameAndDescription";
import { OrganizationIntegrations } from "./custom-fields/OrganizationIntegrations";
import { OrganizationSetup } from "./custom-fields/OrganizationSetup";
import { PersonalInformationCustomField } from "./custom-fields/PersonalInformationCustomField";
import { PluginInferenceTableCustomField } from "./custom-fields/PluginInferenceTableCustomField";
import { PotentialHarmListField } from "./custom-fields/PotentialHarms";
import { RelationshipComplexityField } from "./custom-fields/RelationshipComplexity";
import { Repositories } from "./custom-fields/Repositories";
import { RiskLevelField } from "./custom-fields/RiskLevel";
import { SingleSelectionField } from "./custom-fields/SingleSelection";
import { TermsAndConditions } from "./custom-fields/TermsAndConditions";
import { UserInformation } from "./custom-fields/UserInformation";
import { VendorContactInformation } from "./custom-fields/VendorContactInformation";
import { VendorProcurementRequirementTable } from "./custom-fields/VendorProcurementRequirement";
import { KPIs } from "./custom-fields/KPIs";
import { CollectionStoreListCustomField } from "../molecules/custom-fields/CollectionStoreCustomField";
import { searchCollectionStores } from "../hooks/useCollectionStores";
interface ICustomInputValidationProps {
  value: any;
  valueError: boolean;
  errorMessage: string;
  field_type: CustomFieldType | RegistryFieldType | null;
  field_type_options?: RegistryFieldType[];
  edit_field_type?: RegistryFieldType | null;
  /** Hook to fetch the data for a userId */
  useUser?: (userId: string | null) => { data?: User; isLoading: boolean };
  /** Hook to fetch the data for many userId */
  useUsers?: (params: UserFetchOptions) => { data?: ListResponse<User>; isLoading: boolean };
  /** Callback to search for users */
  searchUsers?: (searchText: string) => Awaitable<User[]>;
  /** Hook to fetch the asset */
  useAsset?: (assetId: string) => { data?: Asset; isLoading: boolean };
  /** Callback to search for assets */
  searchAssets?: (searchText: string) => Awaitable<Asset[]>;
  onChange: (value: any, field_type?: string, models?: string) => void;
  /** Additional values, used for number with unit */
  additionalValue?: any;
  additionalValueError?: boolean;
  additionalValueErrorMessage?: string;
  additionalValueOnChange?: (value: any) => void;
  setObjectAttributes?: (obj: any) => void;
  required?: boolean;
  customModalTitle?: string;
  customExtraComponent?: React.ReactNode;
  fullWidth?: boolean;
  label?: string;
  questionnaireId?: string;
  choices?: QuestionChoice[];
  relatedObjectId?: string;
  assetType?: string;
  model?: string;
  acceptString?: boolean;
  showJSONModal?: boolean;
  disabled?: boolean;
  setCustomTitle?: (customTitle: string) => void;
  relatedObjectName?: string;
  metadata?: any;
}

export const CustomInputValidation = forwardRef((props: ICustomInputValidationProps, ref) => {
  const {
    value,
    onChange,
    field_type,
    field_type_options,
    edit_field_type,
    useUsers = useUsersHook,
    valueError,
    errorMessage,
    useUser = useUserHook,
    searchUsers = searchUsersHook,
    useAsset = useAssetHook,
    searchAssets = searchAssetsHook,
    additionalValue = "",
    additionalValueError = false,
    additionalValueErrorMessage = "",
    additionalValueOnChange,
    setObjectAttributes,
    required,
    customModalTitle = "",
    customExtraComponent,
    fullWidth = true,
    label,
    questionnaireId,
    choices,
    relatedObjectId,
    assetType,
    model,
    acceptString,
    showJSONModal = false,
    disabled = false,
    setCustomTitle,
    relatedObjectName,
    metadata,
  } = props;
  const hasAdditionalValue = !!additionalValueOnChange;
  const [selectedWorkflow, setSelectedWorkflow] = useState<string | null>(null);
  const [selectedFieldType, setSelectedFieldType] = useState<RegistryFieldType>(
    field_type_options ? field_type_options[0] : "String"
  );

  const fieldTypeOptionsExistsAndIsGreaterThanOne =
    field_type_options && isArray(field_type_options) && field_type_options.length > 1;
  const fieldTypeOptionsExistsAndIsSingleOption =
    field_type_options && isArray(field_type_options) && field_type_options.length === 1;

  useEffect(() => {
    if (edit_field_type) setSelectedFieldType(edit_field_type);
  }, [edit_field_type]);

  return (
    <>
      {(() => {
        switch (field_type) {
          case "String":
            return (
              <StringCustomField
                required={required}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
              />
            );
          case "MLFlow Data":
            return (
              <JSONCustomField
                required={required}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
              />
            );
          case "Number":
            return (
              <NumberCustomField
                required={required}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
              />
            );
          case "JSON":
            return (
              <JSONCustomField
                required={required}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
                showJSONModal={showJSONModal}
                label={label}
              />
            );
          case "Date":
            return (
              <DateCustomField
                required={required}
                value={value}
                allowEmpty={!required}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
              />
            );
          case "User":
            return (
              <UserCustomField
                fullWidth={fullWidth}
                required={required}
                setObjectAttributes={setObjectAttributes}
                value={value}
                useUser={useUser}
                searchUsers={searchUsers}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
              />
            );
          case "Asset":
            return (
              <AssetCustomField
                required={required}
                setObjectAttributes={setObjectAttributes}
                value={value}
                useAsset={useAsset}
                searchAssets={searchAssets}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
              />
            );
          case "Boolean":
            return (
              <BooleanCustomField
                required={required}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
              />
            );

          case "Rating Level":
            return (
              <RatingLevelCustomField
                required={required}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
              />
            );
          case "MLFlow Model":
            return (
              <MLFlowModelCustomField
                required={required}
                searchModels={searchMLFlowModels}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
              />
            );
          case "Number with Unit":
            return (
              <NumberWithUnitCustomField
                value={value}
                onChange={onChange}
                required={required}
                errorMessage={errorMessage}
                valueError={valueError}
              />
            );
          case "User list":
            return (
              <UserListCustomField
                label={label}
                required={required}
                fullWidth={fullWidth}
                setObjectAttributes={setObjectAttributes}
                useUsers={useUsers}
                value={value}
                searchUsers={searchUsers}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
                acceptString={acceptString}
              />
            );
          case "Asset list":
            return (
              <AssetListCustomField
                required={required}
                useAssets={useAssets}
                setObjectAttributes={setObjectAttributes}
                value={value}
                searchAssets={searchAssets}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
                label={label}
              />
            );
          case "Dataset list":
            return (
              <AssetListCustomField
                required={required}
                useAssets={useAssets}
                setObjectAttributes={setObjectAttributes}
                value={value}
                searchAssets={searchAssetsWithParams({ "asset_type_name[]": ["Dataset"] })}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
                label={label}
              />
            );
          case "Model list":
            return (
              <AssetListCustomField
                required={required}
                useAssets={useAssets}
                setObjectAttributes={setObjectAttributes}
                value={value}
                searchAssets={searchAssetsWithParams({ "asset_type_name[]": ["Model"] })}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
                label={label}
              />
            );
          case "Monetary Value":
            return (
              <NumberCustomField
                required={required}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
              />
            );
          case "Percentage":
            return (
              <NumberCustomField
                required={required}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
              />
            );
          case "Test Run":
            return (
              <TestRunCustomField
                required={required}
                setObjectAttributes={setObjectAttributes}
                search={searchTestRun}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
              />
            );
          case "Test Run list":
            return (
              <TestRunListCustomField
                required={required}
                setObjectAttributes={setObjectAttributes}
                search={searchTestRun}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
              />
            );
          case "Test Case":
            return (
              <TestCaseCustomField
                required={required}
                setObjectAttributes={setObjectAttributes}
                search={searchTestCases}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
              />
            );
          case "Test Case list":
            return (
              <TestCaseListCustomField
                required={required}
                setObjectAttributes={setObjectAttributes}
                search={searchTestCases}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
              />
            );

          case "Metric":
            return (
              <MetricCustomField
                required={required}
                setObjectAttributes={setObjectAttributes}
                search={searchMetrics}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
              />
            );
          case "Metric list":
          case "KPIs":
            return (
              <MetricListCustomField
                required={required}
                setObjectAttributes={setObjectAttributes}
                search={searchMetrics}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
              />
            );

          case "Role":
            return (
              <RoleCustomField
                required={required}
                setObjectAttributes={setObjectAttributes}
                search={searchRoles}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
              />
            );
          case "Role list":
            return (
              <RoleListCustomField
                required={required}
                setObjectAttributes={setObjectAttributes}
                search={searchRoles}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
              />
            );

          case "Workflow":
            return (
              <WorkflowCustomField
                required={required}
                setObjectAttributes={setObjectAttributes}
                search={searchWorkflows}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
              />
            );
          case "Workflow list":
            return (
              <WorkflowListCustomField
                required={required}
                setObjectAttributes={setObjectAttributes}
                search={searchWorkflows}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
              />
            );

          case "Action":
            return (
              <ActionCustomField
                required={required}
                setObjectAttributes={setObjectAttributes}
                search={searchActions}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
              />
            );
          case "Action list":
            return (
              <ActionListCustomField
                required={required}
                setObjectAttributes={setObjectAttributes}
                search={searchActions}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
              />
            );
          case "Framework":
            return (
              <GenericCustomField<ComplianceFramework, string>
                required={required}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
                search={searchFrameworks}
                useGetData={useFramework}
                setObjectAttributes={setObjectAttributes}
                label="Framework"
                nameKey="name"
                params={{ fairo_data: true }}
                descriptionKey="description"
                disabled={disabled}
              />
            );

          case "Framework list":
            return (
              <GenericListCustomField
                descriptionKey="description"
                nameKey="name"
                required={required}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
                search={searchFrameworks}
                useGetData={useFrameworks}
                setObjectAttributes={setObjectAttributes}
                label="Frameworks"
                params={{ fairo_data: true }}
                disabled={disabled}
              />
            );
          case "Policy":
            return (
              <GenericCustomField
                useGetData={usePolicy}
                descriptionKey="description"
                nameKey="name"
                required={required}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
                search={searchPolicies}
                setObjectAttributes={setObjectAttributes}
                label="Policy"
                params={{}}
              />
            );
          case "Policy list":
            return (
              <GenericListCustomField
                descriptionKey="description"
                nameKey="name"
                required={required}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
                search={searchPolicies}
                useGetData={usePolicies}
                setObjectAttributes={setObjectAttributes}
                label="Policies"
                params={{}}
              />
            );
          case "Element":
            return (
              <GenericCustomField
                useGetData={useComplianceElement}
                descriptionKey="description"
                nameKey="name"
                required={required}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
                search={searchComplianceElements}
                setObjectAttributes={setObjectAttributes}
                label="Element"
                params={{ fairo_data: true }}
              />
            );

          case "Element list":
            return (
              <GenericListCustomField
                descriptionKey="description"
                nameKey="name"
                required={required}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
                search={searchComplianceElements}
                useGetData={useComplianceElements}
                setObjectAttributes={setObjectAttributes}
                label="Elements"
                params={{ fairo_data: true }}
              />
            );
          case "Integration":
            return (
              <GenericCustomField
                useGetData={usePlugin}
                descriptionKey="plugin_description"
                nameKey="plugin_display_name"
                label="Integration"
                required={required}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
                search={searchPlugins}
                setObjectAttributes={setObjectAttributes}
                onChangeKey="plugin_type"
                params={{}}
              />
            );
          case "Integration list":
            return (
              <GenericListCustomField
                useGetData={usePluginsPaginated}
                descriptionKey="plugin_description"
                nameKey="plugin_display_name"
                label="Integrations"
                required={required}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
                search={searchPlugins}
                setObjectAttributes={setObjectAttributes}
                onChangeKey="plugin_type"
                params={{}}
              />
            );
          case "Resource":
            return (
              <GenericCustomField
                useGetData={useResource}
                descriptionKey="external_id"
                nameKey=""
                getName={(resource) => `Resource-${resource.resource_num}`}
                required={required}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
                search={searchResources}
                setObjectAttributes={setObjectAttributes}
                label="Resource"
                params={{}}
              />
            );
          case "Resource list":
            return (
              <GenericListCustomField
                useGetData={useResources}
                descriptionKey="external_id"
                nameKey="id"
                getName={(resource) => `Resource-${resource.resource_num}`}
                required={required}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
                search={searchResources}
                setObjectAttributes={setObjectAttributes}
                label="Resources"
                params={{}}
              />
            );
          case "Evidence":
            return (
              <GenericCustomField
                useGetData={useEvidenceTest}
                descriptionKey="summary"
                nameKey="summary"
                getName={(evidence) => `Evidence-${evidence.evidence_test_num}`}
                params={{ fairo_data: true }}
                required={required}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
                search={searchEvidenceMappings}
                label="Evidence"
              />
            );
          case "Evidence list":
            return (
              <GenericListCustomField
                useGetData={useEvidenceMappings}
                descriptionKey="description"
                nameKey="name"
                getName={(evidence) => `Evidence-${evidence.evidence_test_num}`}
                params={{ fairo_data: true }}
                required={required}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
                search={searchEvidenceMappings}
                label="Evidences"
              />
            );
          case "Reference":
            return (
              <GenericCustomField
                useGetData={useReference}
                descriptionKey="reference_type"
                nameKey="title"
                required={required}
                value={value}
                onChange={onChange}
                params={{ fairo_data: true }}
                error={valueError}
                errorMessage={errorMessage}
                search={searchReferences}
                label="Reference"
                setObjectAttributes={setObjectAttributes}
              />
            );
          case "Reference list":
            return (
              <GenericListCustomField
                descriptionKey="reference_type"
                nameKey="title"
                required={required}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
                search={searchReferences}
                label="References"
                setObjectAttributes={setObjectAttributes}
                useGetData={useReferences}
                params={{}}
              />
            );
          case "Task":
            return (
              <GenericCustomField
                useGetData={useExecutionTask}
                descriptionKey="action_type"
                nameKey="task_num"
                getName={(task) => `Task-${task.task_num}`}
                required={required}
                value={value}
                onChange={onChange}
                params={{}}
                error={valueError}
                errorMessage={errorMessage}
                search={searchExecutionTasks}
                label="Task"
                setObjectAttributes={setObjectAttributes}
              />
            );
          case "Task list":
            return (
              <GenericListCustomField
                useGetData={useExecutionTasks}
                descriptionKey="action_type"
                nameKey="task_num"
                getName={(task) => `Task-${task.task_num}`}
                required={required}
                value={value}
                onChange={onChange}
                params={{}}
                error={valueError}
                errorMessage={errorMessage}
                search={searchExecutionTasks}
                label="Tasks"
                setObjectAttributes={setObjectAttributes}
              />
            );
          case "Parameter":
            return (
              <GenericCustomField
                useGetData={useParameter}
                descriptionKey="description"
                nameKey="name"
                required={required}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
                search={searchParameters}
                label="Parameter"
                setObjectAttributes={setObjectAttributes}
              />
            );
          case "Parameter list":
            return (
              <GenericListCustomField
                useGetData={useParameters}
                descriptionKey="description"
                params={{}}
                nameKey="name"
                required={required}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
                search={searchParameters}
                label="Parameters"
                setObjectAttributes={setObjectAttributes}
              />
            );
          case "Hyperlink":
            return (
              <StringCustomField
                required={required}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
              />
            );
          case "Image":
            return (
              <ImageCustomField
                useGetData={useFile}
                required={required}
                value={value}
                setObjectAttributes={setObjectAttributes}
                params={{}}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
              />
            );
          case "Questionnaire":
            return (
              <GenericCustomField
                useGetData={useQuestionnaire}
                descriptionKey="description"
                getDescription={(questionnaire) => questionnaire.section_name?.name}
                params={{}}
                nameKey="name"
                required={required}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
                search={searchQuestionnaires}
                label="Questionnaire"
                setObjectAttributes={setObjectAttributes}
              />
            );
          case "Question":
            return (
              <QuestionCustomField
                required={required}
                questionnaireId={questionnaireId ?? ""}
                setObjectAttributes={setObjectAttributes}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
              />
            );
          case "Report":
            return null;
          case "Metric Result":
            return (
              <MetricResultCustomField
                required={required}
                setObjectAttributes={setObjectAttributes}
                search={searchMetricResults}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
              />
            );
          case "Metric Result list":
            return (
              <MetricResultListCustomField
                required={required}
                setObjectAttributes={setObjectAttributes}
                search={searchMetricResults}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
              />
            );
          case "Test Result":
            return null;
          case "Department":
            return (
              <GenericCustomField
                useGetData={useDepartment}
                descriptionKey="name"
                getDescription={(department) => ""}
                nameKey="name"
                required={required}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
                search={searchDepartments}
                label="Department"
                setObjectAttributes={setObjectAttributes}
              />
            );
          case "Department list":
            return (
              <GenericListCustomField
                useGetData={useDepartments}
                descriptionKey="name"
                getDescription={(department) => ""}
                params={{}}
                nameKey="name"
                required={required}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
                search={searchDepartments}
                label="Departments"
                setObjectAttributes={setObjectAttributes}
              />
            );
          case "Impact Assessment":
            return (
              <GenericCustomField
                useGetData={useImpactAssessment}
                descriptionKey="name"
                getDescription={(impactAssessment) => ""}
                params={{}}
                nameKey="name"
                required={required}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
                search={searchImpactAssessments}
                label="Impact Assessment"
                setObjectAttributes={setObjectAttributes}
              />
            );
          case "Name and Description":
            return <NameAndDescriptionField {...props} />;
          case "Name and Description list":
            return (
              <NameAndDescriptionListField
                tableDescription={customExtraComponent}
                modalTitle={customModalTitle}
                showAddButton={true}
                {...props}
              />
            );
          case "Text Area":
            return (
              <StringCustomField
                rows={8}
                required={required}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
                minRows={8}
              />
            );
          case "External Link":
            return (
              <ExternalLinkListField
                tableDescription={customExtraComponent}
                modalTitle={customModalTitle}
                showAddButton={true}
                {...props}
              />
            );
          case "IA Stakeholder":
            return (
              <IAStakeHolderListField
                modalTitle={customModalTitle}
                showAddButton={true}
                {...props}
              />
            );
          case "Geographic Areas":
            return (
              <StringCustomField
                rows={8}
                required={required}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
              />
            );
          case "Languages":
            return (
              <StringListCustomField
                required={required}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
                label="Items"
              />
            );
          case "Yes or No":
            return (
              <YesOrNoSelectField
                value={value}
                onChange={onChange}
                choices={choices}
                required={required}
              />
            );
          case "String list":
            return (
              <StringListCustomField
                required={required}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
                label={label}
              />
            );
          case "Use Case":
            return (
              <UseCaseCustomField
                required={required}
                setObjectAttributes={setObjectAttributes}
                search={searchUseCases}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
              />
            );
          case "Use Case list":
            return (
              <UseCaseListCustomField
                required={required}
                setObjectAttributes={setObjectAttributes}
                search={searchUseCases}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
              />
            );
          case "Risk Registry":
            return <Typography variant="body2">Not Implemented</Typography>;
          case "Tag":
            return (
              <TagCustomField
                required={required}
                setObjectAttributes={setObjectAttributes}
                search={searchTags}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
              />
            );
          case "Tag List":
            return (
              <TagListCustomField
                required={required}
                setObjectAttributes={setObjectAttributes}
                search={searchTags}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
              />
            );
          case "Formatted Text":
            return <CKEditorField placeholder={label} value={value} onChange={onChange} />;
          case "Potential Harm":
            return (
              <PotentialHarmListField
                tableDescription={customExtraComponent}
                modalTitle={customModalTitle}
                showAddButton={true}
                {...props}
              />
            );
          case "Vendor":
            return <Typography variant="body2">Not Implemented</Typography>;
          case "Jira Ticket":
            return <JiraTicket {...props} />;
          case "Confluence Page":
            return <Typography variant="body2">Not Implemented</Typography>;
          case "Data Origin":
            return <DataOriginField {...props} />;
          case "Risk Level":
            if (choices) {
              return <RiskLevelField {...props} choices={choices} />;
            }
            return <Typography variant="body2">Missing choices</Typography>;
          case "Relationship Complexity":
            return <RelationshipComplexityField {...props} />;
          case "Single Selection":
            if (choices) {
              return <SingleSelectionField {...props} choices={choices} />;
            }
            return <Typography variant="body2">Missing choices</Typography>;
          case "File or Link":
            return relatedObjectId ? (
              <FileOrLinkCustomField
                relatedObjectId={relatedObjectId}
                value={value}
                onChange={onChange}
                edit={true}
              />
            ) : null;
          case "Personal Information":
            return (
              <PersonalInformationCustomField
                ref={ref}
                errorMessage={errorMessage}
                onChange={onChange}
                value={value}
                valueError={valueError}
                required={required}
              />
            );
          case "Asset with Description list":
            return (
              <AssetWithDescriptionListCustomField
                value={value}
                onChange={onChange}
                assetType={assetType ?? "Dataset"}
                edit={true}
                label={label ?? "Asset"}
              />
            );
          case "Metric with Description list":
            return (
              <MetricWithDescriptionListCustomField
                value={value}
                onChange={onChange}
                edit={true}
                label={label ?? "Metric"}
              />
            );
          case "Metric Result Test Result list":
            return <MetricResultTestResultListCustomField value={value} model={model ?? ""} />;
          case "Vendor Procurement Requirement":
            return (
              <VendorProcurementRequirementTable
                value={value}
                onChange={onChange}
                relatedObjectId={relatedObjectId ?? ""}
              />
            );
          case "Multiple Selection":
            if (choices) {
              return <MultipleSelectionField {...props} choices={choices} />;
            }
            return <Typography variant="body2">Missing choices</Typography>;
          case "Plugin Inference Table":
            return (
              <PluginInferenceTableCustomField edit={true} value={value} onChange={onChange} />
            );
          case "Color Scheme":
            return <ColorSchemeCustomField value={value} onChange={onChange} />;
          case "Repositories":
            return (
              <Repositories value={value} onChange={onChange} objectId={relatedObjectId ?? ""} />
            );
          case "Models":
            return <Models value={value} onChange={onChange} objectId={relatedObjectId ?? ""} />;
          case "Documentation":
            return setCustomTitle && relatedObjectName ? (
              <DocumentationCustomField
                ref={ref}
                relatedObjectName={relatedObjectName}
                setCustomTitle={setCustomTitle}
                value={value}
                onChange={onChange}
                relatedObjectId={relatedObjectId ?? ""}
              />
            ) : (
              <>Missing setCustomTitle prop</>
            );
          case "Organization Setup":
            return (
              <OrganizationSetup
                value={value}
                onChange={onChange}
                required={required}
                ref={ref}
                metadata={metadata}
              />
            );
          case "User Information":
            return (
              <UserInformation
                value={value}
                onChange={onChange}
                required={required}
                ref={ref}
                metadata={metadata}
              />
            );
          case "Co Workers And Role":
            return <CoWorkersAndRole value={value} onChange={onChange} required={required} />;
          case "Organization Integrations":
            return (
              <OrganizationIntegrations
                value={value}
                onChange={onChange}
                required={required}
                metadata={metadata}
              />
            );
          case "Terms and Conditions":
            return (
              <TermsAndConditions
                value={value}
                onChange={onChange}
                required={required}
                choices={choices ?? []}
                ref={ref}
              />
            );
          case "Vendor Contact Information":
            return (
              <VendorContactInformation
                value={value}
                onChange={onChange}
                required={required}
                ref={ref}
              />
            );
          case "File Attachment List":
            return (
              <FileAttachmentList
                value={value}
                onChange={onChange}
                relatedObjectId={relatedObjectId ?? ""}
              />
            );
          case "Collection list":
            return (
              <CollectionStoreListCustomField
                required={required}
                setObjectAttributes={setObjectAttributes}
                search={searchCollectionStores}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
              />
            );
          case null:
            return (
              <>
                {fieldTypeOptionsExistsAndIsGreaterThanOne && (
                  <SearchableDropdown<RegistryFieldType>
                    label="Value Type"
                    required={required}
                    error={false}
                    value={selectedFieldType || null}
                    onChange={(newField) => newField != null && setSelectedFieldType(newField)}
                    getOptions={(search: string) =>
                      field_type_options.filter((option) =>
                        option.toLowerCase().includes(search.toLowerCase())
                      )
                    }
                    isOptionEqualToValue={(a, b) => a === b}
                    getOptionLabel={(option) => option}
                    getSummaryDescription={(option) => [option, ""]}
                  />
                )}
                <CustomInputValidation
                  {...props}
                  onChange={(e) => {
                    onChange(
                      e,
                      fieldTypeOptionsExistsAndIsSingleOption
                        ? field_type_options?.[0]
                        : selectedFieldType
                    );
                  }}
                  field_type={
                    fieldTypeOptionsExistsAndIsSingleOption
                      ? field_type_options?.[0]
                      : selectedFieldType
                  }
                />
              </>
            );

          default:
            // below line makes sure the switch case handles all custom field types
            // removed to cover new cenario where field_type is an array
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const _: never = field_type;
            return (
              <StringCustomField
                required={required}
                value={value}
                onChange={onChange}
                error={valueError}
                errorMessage={errorMessage}
              />
            );
        }
      })()}
    </>
  );
});
