import { Box, useTheme } from "@mui/material";
import { ScreenSmallerThen } from "../../utilities/UIHelper";

type SingleContainerBigLayoutProps = {
  leftComponent: React.ReactNode;
  rightComponent: React.ReactNode;
  hideTopRadiusAndPadding?: boolean;
  topComponent?: React.ReactNode;
  sideBarSize?: "xs" | "sm" | "md" | "lg";
};

export const SingleContainerLayout = ({
  leftComponent,
  rightComponent,
  hideTopRadiusAndPadding,
  topComponent,
  sideBarSize = "md",
}: SingleContainerBigLayoutProps) => {
  const sizes = {
    xs: "100px",
    sm: "246px",
    md: "290px",
    lg: "370px",
  };
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";
  const isSmallScreen = ScreenSmallerThen();
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        padding: "0 0 15px 0",
        // height: isSmallScreen ? "unset" : "100%",
      }}
    >
      {topComponent}
      <Box
        display="flex"
        // minHeight="100%"
        // maxHeight="100%"
        flexDirection="row"
        width="100%"
        bgcolor={theme.palette.custom.primaryBackground}
        border={`1px solid ${theme.palette.custom.primaryBorder}`}
        borderRadius="6px"
        flexWrap={isSmallScreen ? "wrap" : "nowrap"}
        sx={{
          borderTopRightRadius: hideTopRadiusAndPadding ? "0px" : "6px",
          borderTopLeftRadius: hideTopRadiusAndPadding ? "0px" : "6px",
          ...(topComponent && {
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          }),
        }}
      >
        <Box
          display="flex"
          flexDirection={"column"}
          maxWidth={isSmallScreen ? "100%" : sizes[sideBarSize]}
          minWidth={isSmallScreen ? "100%" : sizes[sideBarSize]}
          width="100%"
          height={isSmallScreen ? "unset" : "100%"}
          overflow="auto"
        >
          {leftComponent}
        </Box>
        <Box
          display="flex"
          maxWidth="100%"
          flexGrow={1}
          overflow="auto"
          borderLeft={`1px solid ${theme.palette.custom.secondaryBorder}`}
        >
          {rightComponent}
        </Box>
      </Box>
    </Box>
  );
};
