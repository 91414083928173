import { Box } from "@mui/material";
import { RectangularLoader } from "../../molecules/skeleton-loader/RectangularLoader";

export const UseCaseTemplateLoader = () => {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        padding: "0px 0px 75px 0px",
        gap: "68px",
      }}
    >
      <Box display="flex" flexDirection="column" gap="16px" width="100%">
        <RectangularLoader width="100%" height="27px" />
        <RectangularLoader width="100%" height="34px" />
        <RectangularLoader width="100%" height="30px" />
        <RectangularLoader width="100%" height="264px" />
        <RectangularLoader width="100%" height="264px" />
      </Box>
      <Box display="flex" flexDirection="column" gap="32px" width="100%">
        <RectangularLoader width="100%" height="606px" />
        <RectangularLoader width="100%" height="40px" />
      </Box>
    </Box>
  );
};
