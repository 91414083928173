import { Box, useTheme } from "@mui/material";
import { RectangularLoader } from "../../molecules/skeleton-loader/RectangularLoader";
import { QuestionnaireProgressBar } from "../../molecules/impact-assessment/QuestionnaireProgressBar";
import { ScreenSmallerThen } from "../../utilities/UIHelper";

export const UseCaseWizardLoader = () => {
  const theme = useTheme();
  const isSmallScreen = ScreenSmallerThen();

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        bgcolor={theme.palette.custom.primaryBackground}
        alignItems="center"
        padding="20px"
      >
        <RectangularLoader width="100%" height="50px" />
      </Box>
      <QuestionnaireProgressBar rounded={false} questionIndex={0} questionnaireLength={1} />
      <Box display="flex" flexDirection="row" flexGrow={1}>
        {!isSmallScreen && (
          <Box
            display="flex"
            maxWidth="235px"
            minWidth="235px"
            minHeight="100%"
            flexGrow={1}
            padding="20px"
            gap="5px"
            borderRight={`1px solid ${theme.palette.custom.secondaryBorder}`}
            flexDirection="column"
            bgcolor={theme.palette.custom.primaryBackground}
          >
            <RectangularLoader width="100%" height="30px" />
            <RectangularLoader width="100%" height="30px" />
            <RectangularLoader width="100%" height="30px" />
            <RectangularLoader width="100%" height="30px" />
            <RectangularLoader width="100%" height="30px" />
          </Box>
        )}
        <Box
          display="flex"
          flexDirection="column"
          gap="16px"
          flexGrow={1}
          justifyContent="center"
          alignItems="center"
          padding="20px"
          width="100vw"
        >
          <Box
            display="flex"
            flexDirection={"column"}
            gap="16px"
            flexGrow={1}
            width="100%"
            alignItems="center"
            maxWidth="800px"
          >
            <RectangularLoader width="100%" height="24px" />
          </Box>
          <Box
            display="flex"
            flexDirection={"column"}
            gap="16px"
            flexGrow={1}
            width="100%"
            maxWidth="800px"
            paddingTop="50px"
          >
            <RectangularLoader width="100%" height="20px" />
            <RectangularLoader width="100%" height="20px" />
            <RectangularLoader width="100%" height="36px" />
            <RectangularLoader width="100%" height="36px" />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
