import ShowChartRoundedIcon from "@mui/icons-material/ShowChartRounded";
import { Box, Tooltip, Typography, useTheme } from "@mui/material";
import { getBadgeColor } from "../utilities/BadgeColors";
import { CircularLoader } from "../molecules/skeleton-loader/CircularLoader";

type TaskSuggestionDisplayProps = {
  label: string;
  value: string;
  onClick: () => void;
  description?: string;
  color: string;
};

export const TaskSuggestionDisplay = ({
  label,
  value,
  onClick,
  description,
  color,
}: TaskSuggestionDisplayProps) => {
  const theme = useTheme();
  const badgeColor = getBadgeColor(color);
  return (
    <Box
      onClick={() => onClick()}
      display="flex"
      flexDirection="column"
      gap="3px"
      alignItems="center"
      sx={{ cursor: "pointer" }}
    >
      <Box
        display="flex"
        flexDirection="column"
        borderRadius="50%"
        height="100px"
        width="100px"
        alignItems="center"
        justifyContent="center"
        sx={{
          ":hover": {
            backgroundColor: theme.palette.custom.grey[50],
            cursor: "pointer",
          },
        }}
        gap="3px"
        boxShadow={`0px 0px 10px ${theme.palette.custom.grey[50]}`}
        border={`3px solid ${badgeColor.background}`}
      >
        <Typography variant="small" fontSize={10}>
          {label}
        </Typography>
        <Typography variant="h1" color={badgeColor.typography} lineHeight={0.9} fontSize={20}>
          {value}
        </Typography>
        {description && (
          <Typography
            variant="small"
            fontSize={11}
            sx={{
              padding: "1px 5px",
              backgroundColor: theme.palette.custom.secondaryBorder,
              borderRadius: "6px",
            }}
          >
            {description}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

type TaskSuggestionDisplaySmallProps = {
  label: string;
  value: string | number;
  onClick: () => void;
  isLoading?: boolean;
  direction?: "row" | "column";
};

export const TaskSuggestionDisplaySmall = ({
  label,
  value,
  onClick,
  isLoading = false,
  direction = "column",
}: TaskSuggestionDisplaySmallProps) => {
  const theme = useTheme();
  return (
    <Box
      onClick={() => onClick()}
      display="flex"
      flexDirection={direction}
      gap="3px"
      alignItems="center"
      sx={{ cursor: "pointer" }}
    >
      {!isLoading ? (
        <Box
          display="flex"
          flexDirection="column"
          borderRadius="50%"
          height="28px"
          width="28px"
          alignItems="center"
          justifyContent="center"
          sx={{
            ":hover": {
              backgroundColor: theme.palette.custom.grey[50],
              cursor: "pointer",
            },
          }}
          gap="3px"
          // boxShadow={`0px 0px 10px ${theme.palette.custom.grey[50]}`}
          bgcolor={theme.palette.custom.secondaryBackground}
          border={`1px solid ${theme.palette.custom.grayTypography}`}
        >
          <Typography
            variant="h1"
            color={theme.palette.custom.blueTypography}
            lineHeight={0.9}
            fontSize={12}
          >
            {value}
          </Typography>
        </Box>
      ) : (
        <CircularLoader height="28px" width="28px" />
      )}
      <Typography variant="body2">{label}</Typography>
    </Box>
  );
};

type KPIBadgeProps = {
  label: string;
  value: string;
  color: string;
  onClick?: () => void;
};

export const KPIBadge = ({ label, value, color, onClick }: KPIBadgeProps) => {
  const theme = useTheme();
  const badgeColor = getBadgeColor(color);
  return (
    <Box
      display="flex"
      sx={{
        cursor: "pointer",
      }}
      minWidth="156px"
      maxWidth="156px"
      flexDirection="row"
      alignItems="center"
      gap="5px"
      padding="4px 8px"
      border={`1px solid ${theme.palette.custom.secondaryBorder}`}
      borderRadius="6px"
      onClick={() => onClick && onClick()}
    >
      <ShowChartRoundedIcon
        sx={{
          width: "28px",
          height: "28px",
          padding: "6px",
          color: badgeColor.typography,
          backgroundColor: badgeColor.background,
          borderRadius: "4px",
        }}
      />
      <Box
        display="flex"
        flexDirection="column"
        gap="2px"
        maxWidth="130px"
        width="130px"
        overflow="hidden"
      >
        <Tooltip
          leaveDelay={200}
          enterNextDelay={500}
          enterDelay={500}
          title={label}
          arrow
          placement="bottom"
          sx={{
            cursor: "pointer",
          }}
        >
          <Typography
            variant="small"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            overflow="hidden"
            maxWidth="100%"
          >
            {label}
          </Typography>
        </Tooltip>
        <Tooltip
          leaveDelay={200}
          enterNextDelay={500}
          enterDelay={500}
          title={value}
          arrow
          placement="bottom"
          sx={{
            cursor: "pointer",
          }}
        >
          <Typography
            variant="h4"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            overflow="hidden"
            maxWidth="100%"
          >
            {value}
          </Typography>
        </Tooltip>
      </Box>
    </Box>
  );
};
