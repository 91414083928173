import { Box, Button, Stack, Typography } from "@mui/material";
import { NavbarIcon } from "../../atoms/navbar/Icon";
import { SmallModal } from "../../components/SmallModal";
import { useFile } from "../../hooks/useFiles";
import { FileDetailsModalLoader } from "../../layouts/FileDetailsModalLoader";
import { RectangularLoader } from "../../molecules/skeleton-loader/RectangularLoader";
import { formatSizeFromBytes } from "../../utilities/UIHelper";

type FileDetailsModalProps = {
  /** Should the modal be open? */
  open: boolean;
  /** Callback to close the modal */
  onClose: () => void;
  fileId: string;
};

export const FileDetailsModal = (props: FileDetailsModalProps) => {
  const { open, onClose, fileId } = props;

  const { data: file, isLoading } = useFile(fileId);

  return (
    <SmallModal
      position="right"
      size="small"
      open={open}
      onClose={() => onClose()}
      showCloseButton={false}
      customTitle={
        <Box display="flex" flexDirection="row" gap="10px" alignItems="center">
          <Box
            onClick={() => onClose()}
            sx={{ cursor: "pointer" }}
            display="flex"
            alignItems="center"
          >
            <NavbarIcon
              variant="left-arrow"
              sx={{ width: "22px", minWidth: "22px", height: "22px", minHeight: "22px" }}
            />
          </Box>
          {!isLoading && <Typography variant="h2">{file?.filename}</Typography>}
          {isLoading && <RectangularLoader height="16px" width="100%" />}
        </Box>
      }
      isLoading={isLoading}
      title={file?.filename}
      action={
        <Button fullWidth onClick={() => window.open(file?.file_object, "_blank")}>
          Open File
        </Button>
      }
    >
      <Stack gap="10px">
        {(!file || isLoading) && <FileDetailsModalLoader />}
        {file && (
          <>
            <Box display="flex" flexDirection="row" gap="10px" alignItems="center">
              <Typography minWidth="100px" variant="h4">
                File Type
              </Typography>
              <Typography variant="body2">{file?.mimetype}</Typography>
            </Box>
            {file.filesize && (
              <Box display="flex" flexDirection="row" gap="10px" alignItems="center">
                <Typography minWidth="100px" variant="h4">
                  File Size
                </Typography>
                <Typography variant="body2">
                  {formatSizeFromBytes(parseInt(file.filesize ?? ""))}
                </Typography>
              </Box>
            )}
            {file?.description && (
              <>
                <Typography minWidth="100px" variant="h4">
                  Description
                </Typography>
                <Typography variant="body2">{file?.description}</Typography>
              </>
            )}
          </>
        )}
      </Stack>
    </SmallModal>
  );
};
