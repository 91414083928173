import { Box, Stack, useTheme } from "@mui/material";
import { SmallModal } from "../../../components/SmallModal";
import { MetricKPIs } from "../../MetricKPIs";
import { KPIBadge } from "../../TaskSuggestionDisplay";

type AllKPIsProps = {
  open: boolean;
  onClose: () => void;
  value: string[];
};

export const AllKPIs = ({ open, onClose, value }: AllKPIsProps) => {
  const theme = useTheme();
  return (
    <SmallModal open={open} onClose={onClose} title="All KPIs" size="medium">
      <Stack gap="10px">
        {value.length !== 0 ? (
          <Box display="flex" gap="8px" flexWrap="wrap">
            {value?.map((metric: any, index: number) => {
              return <MetricKPIs key={index} metricId={metric} />;
            })}
          </Box>
        ) : (
          <KPIBadge color={theme.palette.custom.secondaryStatusColor} label="No KPIs" value="-" />
        )}
      </Stack>
    </SmallModal>
  );
};
