import { Box, Typography } from "@mui/material";
import { JSONView } from "../molecules/JSONView";
import { isJSON } from "../utilities/UIHelper";
import { useState } from "react";
import { ExpandableTitle } from "./ExpandableTitle";
import { StatusBadge } from "../atoms/StatusBadge";
import { TableTextWithEllipsis } from "../molecules/TableTextWithEllipsis";

export const DisplayHyperparameterValue = ({ value }: { value: any }) => {
  const type = value
    ? typeof value === "string"
      ? isJSON(value)
        ? typeof JSON.parse(value)
        : typeof value
      : typeof value
    : "undefined";
  return (
    <>
      {(() => {
        switch (type) {
          case "object":
            const newVal = typeof value === "string" ? JSON.parse(value) : value;
            if (Array.isArray(newVal)) {
              return <DisplayListHyperparamterValue value={newVal} />;
            } else {
              return <DisplayJSONHyperparamterValue value={newVal} />;
            }
          case "undefined":
            return <Typography variant="body2">None</Typography>;
          case "number":
          case "string":
          default:
            return <TableTextWithEllipsis variant="body2" value={value?.toString()} />;
        }
      })()}
    </>
  );
};

export const DisplayJSONHyperparamterValue = ({ value }: { value: any }) => {
  const [expanded, setExpanded] = useState<boolean>(true);

  return (
    <Box width="100%" paddingY="8px" display="flex" flexDirection="column" gap="4px">
      <ExpandableTitle
        expanded={expanded}
        setExpanded={setExpanded}
        leftComponent={<StatusBadge status="JSON" />}
        gap="4px"
      />
      {expanded && <JSONView value={JSON.stringify(value)} />}
    </Box>
  );
};

export const DisplayListHyperparamterValue = ({ value }: { value: any }) => {
  const [expanded, setExpanded] = useState<boolean>(true);

  return (
    <Box width="100%" paddingY="8px" display="flex" flexDirection="column" gap="4px">
      <ExpandableTitle
        expanded={expanded}
        setExpanded={setExpanded}
        leftComponent={<StatusBadge status="LIST" />}
        gap="4px"
      />
      {expanded && (
        <Box display="flex" flexDirection="column" gap="4px">
          {value.map((item: any, index: number) => {
            return <Typography variant="body2">{`${index + 1}) ${item}`}</Typography>;
          })}
        </Box>
      )}
    </Box>
  );
};
